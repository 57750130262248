import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
// import MaterialReactTable from 'material-react-table';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import FilterListIcon from '@mui/icons-material/FilterList';
import * as XLSX from 'xlsx';
import { Box, Button } from '@mui/material';
import _ from 'lodash';
import { headDefultStyles } from '../../helpers/tableDefinitions';
import { useEffect, useContext } from 'react';
import { Context } from '../../context/Context';
import { SimpleIconButton } from 'components/ContentContainer';

const ReactDataTable = ({
  data,
  columns,
  hideColumns,
  fixedColumns = [],
  isError,
  isLoading,
  isRefetching,
  density = 'comfortable',
  module = 'data',
  enableMultiSort = false,
  onSortingChange,
  sorting,
  defaultColumnSort = 'orchard',
  enableColumnPinning = true,
  onPaginationChange,
  pagination,
  enableHiding = true,
  rowCount,
  enableColumnFilters = false,
  onColumnFiltersChange,
  columnFilters,
  enableGlobalFilter = false,
  onGlobalFilterChange,
  globalFilter,
  enableRowActions,
  renderRowActions,
  headRowStyles = {},
  headStyles,
  enableClickToCopy = false,
  enableStickyHeader = false,
  positionActionsColumn = 'last',
  positionPagination = 'bottom',
  arrayHeadersColor = [],
  enableRowSelection = false,
  onRowSelectionChange,
  rowSelection = {},
  handleDeleteRows,
  resetRowSelection,
}) => {
  const { userOrchard, userClient } = useContext(Context);

  function getLastLevelColumns(data) {
    // deja solo las columnas que no tengan subcolumnas
    return data.reduce((acc, item) => {
      if (Array.isArray(item.columns)) {
        const subColumns = getLastLevelColumns(item.columns);
        return acc.concat(subColumns);
      } else {
        return acc.concat(item);
      }
    }, []);
  }

  function downloadXLS(array, cols, moduleName) {
    console.log(array, '-array-------download-', cols);

    const hasColumnsArray = cols.some((item) => Array.isArray(item.columns));

    let allColumns = [];

    // verifica si es una cabecera con subcolumnas
    if (hasColumnsArray) {
      allColumns = getLastLevelColumns(cols);
    } else {
      allColumns = cols;
    }

    allColumns = allColumns.filter((col) => !col.noExport);

    console.log(hasColumnsArray, '----------', allColumns);

    const exportCols = allColumns.map((col) => {
      return col.accessorKey;
    });

    const newArray = array.map((item) => _.pick(item, exportCols));

    // console.log('----->', newArray, '----newArray');

    // headers
    const exportColsHeaders = allColumns.map((col) => {
      return col.header;
    });

    // console.log(exportColsHeaders, "-----exportColsHeaders");

    // let newHeaders = exportColsHeaders.join(columnDelimiter);

    const newHeadersArray = [exportColsHeaders];

    const ws = XLSX.utils.json_to_sheet(newArray, {
      origin: 'A2',
      skipHeader: true,
    });
    const wb = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, newHeadersArray, { origin: 'A1' });

    XLSX.utils.book_append_sheet(wb, ws, moduleName);
    XLSX.writeFile(wb, 'reports.xlsx');
  }

  // const handleExportRows = (rows, cols, moduleName) => {
  // 	// console.log(rows, 'z------');
  // 	downloadXLS(
  // 		rows.map((row) => row.original),
  // 		cols,
  // 		moduleName
  // 	);
  // };

  const objColsNotVisibles = (arr) => {
    const string = {};
    arr.forEach((el) => Object.assign(string, { [el]: false }));

    return string;
  };

  // const customHeadStyles = {
  //   sx: (theme) => ({
  //     ...headDefultStyles,
  //     ...headStyles,
  //   }),
  // };

  const customHeadStyles = (props) => {
    const { column } = props;

    // aplica headStyles a las columnas que estén en arrayHeadersColor
    const colorHeaders = arrayHeadersColor.includes(column.id)
      ? headStyles
      : {};

    return {
      sx: (theme) => ({
        ...headDefultStyles,
        ...colorHeaders,
      }),
    };
  };

  const tableOptions = useMaterialReactTable({
    columns: columns,
    data: data,
    initialState: {
      showColumnFilters: true,
      // col oculta al inicio, por id
      columnVisibility: objColsNotVisibles(hideColumns),
      columnPinning: { left: fixedColumns },
    },
    // state
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      // densidad de las filas
      density: density,
      // rowSelection, solo para controlar el estado de la selección
    },
    // cabecera de botones
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          // gap: '1rem',
          // p: '0.5rem',
          marginRight: 2,
          // flexWrap: 'wrap',
          // alignItems: 'center',
          // justifyContent: 'flex-end',
        }}
      >
        {/* {rowSelection ? <h2>Hay Selected</h2> : null} */}

        {table.getSelectedRowModel().rows &&
        table.getSelectedRowModel().rows.length ? (
          <SimpleIconButton
            actionOnClick={() =>
              handleDeleteRows(table.getSelectedRowModel().rows)
            }
            sx={{ marginRight: 6 }}
            labelButton='Eliminar Conteos'
            color='error'
            size='medium'
            variant='contained'
            icon={<DeleteIcon />}
          />
        ) : null}

        {userClient?.id !== '63ffb857303f71e5a78306e9' ? (
          <Button
            color='primary'
            // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() => downloadXLS(data, columns, module)}
            startIcon={<FileDownloadIcon />}
            variant='contained'
          >
            Exportar
          </Button>
        ) : null}
        {/* <Button
						color="primary"
						//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
						onClick={() =>
							handleExportRows(
								table.getRowModel().rows,
								columns,
								module
							)
						}
						startIcon={<FileDownloadIcon />}
						variant="contained"
					>
						Exportar Filas
					</Button> */}
      </Box>
    ),
    // estilo a cabecera
    // muiTableHeadCellProps: customHeadStyles,
    muiTableHeadCellProps: (props) => customHeadStyles(props),
    // estilo a filas cabecera
    muiTableHeadProps: headRowStyles,
    // estilos a filas head, le quita shadow
    muiTableHeadRowProps: ({ table, headerGroup }) => ({
      sx: (theme) => ({
        boxShadow: 'none',
        '&:last-child th': {
          paddingTop: theme.spacing(1.2),
          paddingBottom: theme.spacing(1.2),
        },
      }),
    }),
    // estilos Container tabla
    muiTableBodyCellProps: ({ cell, column, row, table }) => ({
      sx: (theme) => ({
        ...theme.typography.cellTable,
        borderColor: theme.palette.divider,
      }),
    }),
    // estilo de contenedor Tabla
    muiTablePaperProps: ({ table }) => ({
      elevation: 0, // change the mui box shadow
      // custom zindex
      style: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
      // customize paper styles
      sx: {
        border: 'none',
        // si es pagination TOP
        '& .MuiTablePagination-root':
          positionPagination === 'top'
            ? {
                position: 'absolute',
                mt: 0,
                justifySelf: 'flex-start',
              }
            : 'none',
        '@media (max-width: 1060px)': {
          '& .MuiTablePagination-root': {
            position: 'relative',
          },
        },
      },
    }),
    // estilo del footer
    muiBottomToolbarProps: {
      sx: (theme) => ({
        backgroundColor: 'white',
      }),
    },
    // estilo del top tool bar
    muiTopToolbarProps: {
      sx: (theme) => ({
        backgroundColor: 'white',
        justifyContent: 'flex-end',
      }),
    },
    // filtrar col server side
    manualFiltering: true,
    // paginar server side
    manualPagination: true,
    // hacer sort server side
    manualSorting: true,
    // permitir fijar cols
    // enablePinning
    enableColumnPinning: enableColumnPinning,
    // permitir fijar filas
    enableRowPinning: false,
    // permitir ocultar cols
    enableHiding: enableHiding,
    // habilitar sticky header
    enableStickyHeader: enableStickyHeader,
    // altura máxima si es sticky
    muiTableContainerProps: enableStickyHeader && {
      sx: { maxHeight: '700px' },
    },
    // click para copiar
    enableClickToCopy: enableClickToCopy,
    // no poder cambiar padding filas
    enableDensityToggle: false,
    // no permite búsqueda global
    enableGlobalFilter: enableGlobalFilter,
    // no permite filtrar
    enableColumnFilters: enableColumnFilters,
    // habilitar seleccion de filas
    enableRowSelection: enableRowSelection,
    // evento de seleccion de filas
    // onRowSelectionChange: onRowSelectionChange, solo necesario para controlar el estado de la selección
    // posicion de la columna de acciones
    // positionToolbarAlertBanner: 'bottom', //show alert banner over table head, top toolbar, or bottom toolbar

    // habilitar el multi Sort
    enableMultiSort: enableMultiSort,
    localization: MRT_Localization_ES,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error Cargando data',
        }
      : {
          color: 'warning',
          sx: {
            backgroundColor: 'rgba(254, 199, 111,.4)',
            borderRadius: '6px',
          }, //only show top toolbar progress bar
        },
    // muiToolbarAlertBannerProps: ({ isTopToolbar }) => {
    //   console.log(isTopToolbar, 'props');

    //   return {
    //     color: 'warning',
    //     sx: { display: !isTopToolbar ? 'block' : 'none' }, //only show top toolbar progress bar
    //   };
    // },
    onColumnFiltersChange: onColumnFiltersChange,
    onGlobalFilterChange: onGlobalFilterChange,
    onPaginationChange: onPaginationChange,
    onSortingChange: onSortingChange,
    rowCount: rowCount,
    // position action column
    positionActionsColumn: positionActionsColumn,
    // position pagination
    positionPagination: positionPagination,
    // estilos para pagination "top"
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 30, 50, 100, 200, 300, 500],
    },
    // habilitar columna acciones
    enableRowActions: enableRowActions,
    // componente para la columna acciones
    renderRowActions: ({ row }) => renderRowActions(row),
    defaultColumn: {
      size: 180,
    },

    icons: {
      ViewColumnIcon: (props) => <FilterListIcon {...props} />,
    },
  });

  // aqui se ocultan las columnas, cuando llega un cambio
  useEffect(() => {
    tableOptions.setColumnVisibility(objColsNotVisibles(hideColumns));
  }, [hideColumns]);

  useEffect(() => {
    if (resetRowSelection) {
      tableOptions.resetRowSelection();
    }
  }, [resetRowSelection]);

  return <MaterialReactTable table={tableOptions} />;
};

export default ReactDataTable;
