import { alterArrayGetProperty, alterArrayGetValues } from 'helpers';

export const filtersReducer = (state, action) => {
  switch (action.type) {
    case 'set_user_orchard':
      const getUniqueSpeciesObj = (dataArray) => {
        const speciesMap = new Map();

        dataArray.forEach((item) => {
          item.specie.forEach((specieObj) => {
            if (!speciesMap.has(specieObj.value)) {
              speciesMap.set(specieObj.value, specieObj);
            }
          });
        });

        return Array.from(speciesMap.values());
      };

      const userSpecies = getUniqueSpeciesObj(action.payload);

      const speciesValues = alterArrayGetProperty(userSpecies, 'value');

      // si userSpecies contiene 'cerezo' specieDefault = cerezo sino la primera
      const specieDefault = speciesValues.includes('Cerezo')
        ? 'Cerezo'
        : speciesValues[0];

      return {
        ...state,
        isLoading: false,
        withDataOrchards: true,
        userOrchard_filter: action.payload,
        userSpecies_filter: userSpecies,
        specie_filter: specieDefault,
      };

    case 'is_loading_orchards':
      return {
        ...state,
        isLoading: action.payload,
      };

    case 'add_filter':
      let newObj = {};

      Object.entries(action.payload).forEach(([key, value]) => {
        return (newObj = {
          ...newObj,
          [key]: value,
        });
      });

      // console.log('----->', state, '---', newObj);

      return {
        ...state,
        ...newObj,
      };

    case 'log_out':
      return action.payload;

    default:
      return state;
  }
};
