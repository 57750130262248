import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Grid,
  Drawer,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { subDays, addDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import React, { useContext, useEffect, useState } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import NumberFormat from 'react-number-format';
import ClearIcon from '@mui/icons-material/Clear';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SimpleIconButton,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  dateFormatIso,
  dateFrontConvert,
  getOrchardVarietiesDeep,
  getUniqueValuesMaster,
  numberFormatDecimals,
} from '../../../helpers';
import { tabItems } from './tabList';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import { TabCertification } from '../../../containers/Scheduling';
import Params from '../../Params';
import { FormPrompt } from '../../../helpers/FormPrompt';
import { MobileFilters } from 'containers/Library';

const drawerWidth = '40vw';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  titleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
    marginBottom: '15px !important',
    marginTop: '20px !important',
  },
  weekTitleStyle: {
    fontSize: '18px !important',
    fontWeight: 600 + ' !important',
    // marginTop: '20px !important',
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    maxWidth: '90px !important',
    minWidth: '70px !important',
  },
  dividerRow: {
    marginTop: 30,
  },
  iconText: {
    marginRight: 10,
  },
  OddColStyle: {
    backgroundColor: 'rgba(227,235,237,.3)',
  },
  tableStyle: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& tbody tr td': {
      borderTop: 'none !important',
    },
  },
  varietyNameStyle: {
    fontWeight: 600,
    fontSize: 15,
    paddingTop: 10 + 'px !important',
    width: '120px',
  },
  legendStyle: {
    position: 'absolute',
    right: 0,
    top: 10,
    // media query para pantallas menores a 768px
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      marginBottom: 10,
    },
  },
  daysTableStyle: {
    // borderRight: '1px solid #e3e3e3',
    // borderBottom: '1px solid #e3e3e3',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '8px',
      padding: '0px 10px',
      '& i': {
        fontSize: '17px',
        marginRight: '9px',
      },

      '& i.fa-check-square': {
        color: theme.palette.success.main,
      },
    },
  },
  rowVarietiesStyle: {
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& .varietyBox': {
      paddingLeft: '5px',
      paddingRight: '5px',
      width: '408px',
      backgroundColor: 'rgba(245,245,245,.4)',
      marginRight: '8px',
      marginBottom: '5px',
      borderTop: '3px solid',
      borderTopColor: theme.palette.primary.main,
    },
    '& .varietyBox table': {
      marginBottom: '0px',
    },
    // '& .varietyBox table td:not(:first-child)': {
    // '& .varietyBox table td': {
    //   textAlign: 'center',
    // },
    '& .varietyBox table td': {
      width: '25%',
      overflow: 'hidden',
      wordBreak: 'break-all',
      whiteSpace: 'normal',
      textAlign: 'center',
    },
    // '& .varietyBox table td:nth-child(1)': {
    //   width: '110px',
    //   overflow: 'hidden',
    //   wordBreak: 'break-all',
    //   whiteSpace: 'normal',
    // },
    '& .varietyBox table td.varietyTitleStyle': {
      textAlign: 'center',
      padding: '5px 0px',
    },
    '& .varietyBox table td:nth-child(1)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td:nth-child(2)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td p': {
      fontSize: '12px',
    },
  },
  weekContainerTitleStyle: {
    backgroundColor: '#d9eef4',
    padding: '5px 8px',
    '& h6': {
      // color: 'white',
      padding: '5px 0px',
      marginBottom: '0px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
}));

/**
 * Inverts a number.
 *
 * @param {number} number - The number to be inverted.
 * @returns {number} The inverted number.
 */
const invertNumber = (number = 0) => {
  return number === 0 ? number : number * -1;
};

//
function getActualValueVarietyDay(data, day, variety) {
  // Buscar el objeto correspondiente al día especificado
  const dayData = data.find((week) =>
    week.values.some((dayValue) => dayValue.day === day)
  );

  // console.log(data, day, variety, '<<<<<------------', dayData);

  if (!dayData) {
    // console.log(`No se encontraron datos para el día ${day}`);
    return null;
  }

  const DayObject = dayData.values.find((dayValue) => dayValue.day === day);

  // console.log(DayObject, '<<<<<------------', day);

  const varietyValue = DayObject.values.find(
    (dayValue) => dayValue.varietyValue === variety
  );

  // console.log(varietyValue, '<<<<<------------', DayObject, variety);

  if (!varietyValue) {
    // console.log(`No se encontró el valor de ${variety} para el día ${day}`);
    return null;
  }

  return varietyValue.kilos;
}

// formato {value: 'lunes', label: 'Lunes'}
// crear array con formato
const WEEK_DAYS = [
  { value: 'lunes', label: 'Lunes' },
  { value: 'martes', label: 'Martes' },
  { value: 'miercoles', label: 'Miércoles' },
  { value: 'jueves', label: 'Jueves' },
  { value: 'viernes', label: 'Viernes' },
  { value: 'sabado', label: 'Sábado' },
  { value: 'domingo', label: 'Domingo' },
];

function hasKilogramsDefined(obj) {
  for (const variety in obj) {
    const dates = obj[variety];
    for (const date in dates) {
      if (dates[date].kilograms !== undefined && dates[date].kilograms !== '') {
        return true;
      }
    }
  }
  return false;
}

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const endPointName = 'v1/planningHarvest/calendarization';
const endPointNamePost = 'v1/planningHarvest';
const endPointFilters = 'v1/libraryExternal/specie';

const FormSchedule = (props) => {
  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  const { window } = props;

  const axiosContext = useContext(AxiosContext);

  const { moduleUser, userClient, userOrchard, userId } = useContext(Context);

  const [garces2State, setGarces2State] = useState(false);

  const errorHandler = useErrorHandler();

  const [valueTabState, setValueTabState] = useState(0);
  const [tabsArrayState, setTabsArrayState] = useState([]);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [agroBusinessOptionsState, setAgroBusinessOptionsState] = useState([]);

  const [specieOptionsState, setSpecieOptionsState] = useState([]);

  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataGroupState, setFilterDataGroupState] = useState([]);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // const [filterOrchardState, setFilterOrchardState] = useState(
  //   userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  // );

  const [filterOrchardState, setFilterOrchardState] = useState([]);

  const [fullDataOrchardState, setFullDataOrchardState] = useState([]);

  const [dataFilterState, setDataFilterState] = useState([]);

  const [filterDataAgroBusinessState, setFilterDataAgroBusinessState] =
    useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [specieDataState, setSpecieDataState] = useState([]);

  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  const [loadingVarietiesState, setLoadingVarietiesState] = useState(false);

  // dates

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);

  const [noMessageKilosState, setNoMessageKilosState] = useState(false);

  const [successSubmitState, setSuccessSubmitState] = useState(false);

  const [totalKilosPerVarietyState, setTotalKilosPerVarietyState] = useState(
    {}
  );

  const [copyButtonState, setCopyButtonState] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);

  const [excelLoadingState, setExcelLoadingState] = useState(false);

  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmitForm,
    control: controlForm,
    watch: watchForm,
    setValue: setValueForm,
    reset: resetForm,
    getValues: getValuesForm,
    formState: { errors: errorsForm, isDirty },
  } = useForm();

  const [dataDaysState, setDataDaysState] = useState([]);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useAgroBusiness = useWatch({
    control,
    name: 'agroBusiness',
  });

  const useSpecie = useWatch({
    control,
    name: 'specie',
  });

  const useProductionLine = useWatch({
    control,
    name: 'productionLine',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const useGroup = useWatch({
    control,
    name: 'group',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const formUpdate = useWatch({
    control: controlForm,
  });

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    setValue('until', addDays(new Date(watchSince), 14));
    setMaxDateValidationState(addDays(new Date(watchSince), 30));
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        setFiltersFlattenDataState(flattenEndpointData);

        // cargar con valores únicos
        const agroBusinessArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.agroBusiness,
          };
        });

        const uniqueAgroBusiness = getUniqueValuesMaster(
          agroBusinessArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueAgroBusiness.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setAgroBusinessOptionsState(uniqueAgroBusiness);

        const specieArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.specie,
          };
        });

        const uniqueSpecie = getUniqueValuesMaster(
          specieArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueSpecie, '<<<<<------uniqueSpecie------')

        setSpecieOptionsState(uniqueSpecie);

        const varietyArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.variety,
          };
        });

        const uniqueVariety = getUniqueValuesMaster(
          varietyArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueVariety, '<<<<<------uniqueVariety------')

        setVarietyOptionsState(uniqueVariety);

        const groupArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.group,
          };
        });

        const uniqueGroup = getUniqueValuesMaster(
          groupArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueGroup.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataGroupState(uniqueGroup);

        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueTechnicBoss.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataTechnicBossState(uniqueTechnicBoss);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    // getFilterVarietyAsync();

    getFilterAsync();
  }, []);

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const agroBusinessFilter = useAgroBusiness
        ? ele?.agroBusiness.value === useAgroBusiness.value
        : true;
      const orchardFilter = useOrchard
        ? ele?.orchard.value === useOrchard.value
        : true;
      const specieFilter = useSpecie
        ? ele?.specie.value === useSpecie.value
        : true;
      const productLineFilter = useProductionLine
        ? ele?.productLine.value === useProductionLine.value
        : true;
      // const varietyFilter = useVariety
      //   ? ele?.variety.value === useVariety.value
      //   : true;
      const groupFilter = useGroup ? ele?.group.value === useGroup.value : true;
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return (
        agroBusinessFilter &&
        orchardFilter &&
        specieFilter &&
        productLineFilter &&
        // varietyFilter &&
        groupFilter &&
        technicBossFilter
      );
    });

    // console.log(filterUpdatedData, '<<<<<------filterData------');
    const filterUpdatedDataVariety = filterUpdatedData.filter((ele) => {
      // buscarlo con some en el array de useOrchard
      const varietyFilter =
        useVariety && useVariety.length
          ? useVariety.some((variety) => variety.value === ele?.variety.value)
          : true;

      return varietyFilter;
    });

    // console.log(filterUpdatedDataVariety, '<<<<<------filterDataVariety------');

    const agroBusinessArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.agroBusiness,
      };
    });

    const uniqueAgroBusiness = getUniqueValuesMaster(
      agroBusinessArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setAgroBusinessOptionsState(uniqueAgroBusiness);

    if (!useAgroBusiness && uniqueAgroBusiness.length === 1) {
      setValue('agroBusiness', uniqueAgroBusiness[0]);
    }

    const orchardArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterOrchardState(uniqueOrchards);

    if (!useOrchard && uniqueOrchards.length === 1) {
      setValue('orchard', uniqueOrchards[0]);
    }

    const specieArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.specie,
      };
    });

    const uniqueSpecie = getUniqueValuesMaster(
      specieArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setSpecieOptionsState(uniqueSpecie);

    if (!useSpecie && uniqueSpecie.length === 1) {
      console.log(uniqueSpecie, '<<<<<------Entra para seterlo otra vez------');

      setValue('specie', uniqueSpecie[0]);
    }

    const productLineArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.productLine,
      };
    });

    const uniqueProductLine = getUniqueValuesMaster(
      productLineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setProductionLineDataState(uniqueProductLine);

    // if (!useProductionLine && uniqueProductLine.length === 1) {
    //   setValue('productionLine', uniqueProductLine[0]);
    // }

    const varietyArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyOptionsState(uniqueVariety);

    const groupArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.group,
      };
    });

    const uniqueGroup = getUniqueValuesMaster(
      groupArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueGroup.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataGroupState(uniqueGroup);

    if (!useGroup && uniqueGroup.length === 1) {
      setValue('group', uniqueGroup[0]);
    }

    const technicBossArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueTechnicBoss.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataTechnicBossState(uniqueTechnicBoss);
  }, [
    useAgroBusiness,
    useOrchard,
    useSpecie,
    useProductionLine,
    useVariety,
    useGroup,
    useTechnicBoss,
  ]);

  // cada vez que cambia el formulario se ejecuta
  useEffect(() => {
    const newArray = [];

    setCopyButtonState(hasKilogramsDefined(formUpdate));

    for (const varietyKey in formUpdate) {
      if (Object.prototype.hasOwnProperty.call(formUpdate, varietyKey)) {
        const varietyName = varietyKey.split('-')[1];
        const varietyData = formUpdate[varietyKey];

        // Recorre las fechas dentro de cada variedad
        for (const dateKey in varietyData) {
          if (Object.prototype.hasOwnProperty.call(varietyData, dateKey)) {
            const dateValue = varietyData[dateKey];

            let kilosToNumber = null;
            if (dateValue.kilograms) {
              kilosToNumber = dateValue.kilograms.replace(/\./g, '');
              kilosToNumber = kilosToNumber.replace(',', '.');
              kilosToNumber =
                parseFloat(kilosToNumber) -
                getActualValueVarietyDay(
                  dataDaysState.days,
                  dateKey,
                  varietyName
                );
            }

            // acumula en una variable los kilos
            const kilos = kilosToNumber ? parseFloat(kilosToNumber) : 0;

            const newObject = {
              varietyValue: varietyName,
              kilos: kilos,
            };

            // Agrega el nuevo objeto al array
            newArray.push(newObject);
          }
        }
      }
    }

    // deja un objeto con la suma de kilos por variedad
    const kilosAcumPerVariety = newArray.reduce((acc, curr) => {
      const varietyValue = curr.varietyValue;
      const kilos = curr.kilos;

      if (!acc[varietyValue]) {
        acc[varietyValue] = 0;
      }

      acc[varietyValue] += kilos;
      return acc;
    }, {});

    // console.log(kilosAcumPerVariety, '<<<<<------------');

    // mantiene el total kilos ingresados por variedad

    setTotalKilosPerVarietyState(kilosAcumPerVariety);
  }, [formUpdate]);

  const onSubmit = async (values) => {
    setNoMessageKilosState(false);

    const dateBegin = values.since || '';
    const dateEnd = values.until || '';

    const dateBeginFormat = dateFormatIso(dateBegin);
    const dateEndFormat = dateFormatIso(dateEnd);

    if (dateBeginFormat > dateEndFormat) {
      // console.log(dateBeginFormat, dateEndFormat, '<<<<<------------');
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    setDataDaysState([]);
    setLoadingSubmitFilterState(true);

    resetForm();

    // sacar el variety de la data de variedades
    // const resultVariety = varietyFullDataState.filter(
    //   (ele) => ele.value === values.variety
    // );

    // TODO: agregar Agrobusiness

    console.log(values, '<<<<<------------');

    // AGREGADO de specie y productionLine
    const dataQuery = {
      clientValue: userClient.value,
      orchardValue: values.orchard.value,
      // agroBusiness: values?.agroBusiness?.label,
      agroBusinessValue: values?.agroBusiness?.value,
      varieties: values.variety && values.variety.length ? values.variety : [],
      // variety: values.variety?.label || '',
      specieValue: values.specie.value,
      productLineValue: values?.productionLine?.value || '',
      dateSince: dateFormatIso(dateBegin),
      dateUntil: dateFormatIso(dateEnd),
    };

    const resultOrchard = userOrchard_filter.filter(
      (ele) => ele.value === values.orchard.value
    );

    // console.log(values.orchard, orchard_filter, '---------evalua huertos');
    setInfoFilterTab({ orchard: values.orchard, ...values });

    // if (orchard_id_filter !== resultOrchard[0]?._id) {
    //   addFilter({
    //     cc_filter: '',
    //     quarter_filter: '',
    //   });
    // }
    // addFilter({
    //   orchard_filter: values.orchard.value,
    //   orchard_id_filter: resultOrchard[0]._id,
    // });

    console.log(dataQuery, '<<<<<------------data query');

    // const paramsQuery = {
    //   params: dataQuery,
    // };

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        dataQuery
      );

      const { data, status } = response;

      // console.log(data, '<<<<<-------------------', paramsQuery);

      if (status === 200) {
        setDataDaysState(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitFilterState(false);
    }
  };

  const onReset = (field) => {
    // console.log(field, '<<<<<<<<<', formUpdate);
    setValueForm(field, '');
    // setExpectedInfoState([]);
  };

  const onClearDayValue = (field) => {
    console.log(field, '<<<<<<<<<', formUpdate);
    setValueForm(field, '0');
    // setExpectedInfoState([]);
  };

  const onSubmitForm = async (values) => {
    // console.log(values, '<<<<<------------');
    setNoMessageKilosState(false);

    const newArray = [];

    let hasKilos = false;

    for (const varietyKey in values) {
      if (Object.prototype.hasOwnProperty.call(values, varietyKey)) {
        const varietyName = varietyKey.split('-')[1];
        const varietyData = values[varietyKey];

        // Recorre las fechas dentro de cada variedad
        for (const dateKey in varietyData) {
          if (Object.prototype.hasOwnProperty.call(varietyData, dateKey)) {
            const dateValue = varietyData[dateKey];

            let kilosToNumber = null;
            if (dateValue.kilograms) {
              hasKilos = true;
              kilosToNumber = dateValue.kilograms.replace(/\./g, '');
              kilosToNumber = kilosToNumber.replace(',', '.');
            }

            // const kilos = kilosToNumber ? parseFloat(kilosToNumber) : 0;
            const kilos = kilosToNumber ? parseFloat(kilosToNumber) : null;
            // const cj = dateValue.cj || false;
            // const mexico = dateValue.mexico || false;
            // const quarantine = dateValue.quarantine || false;

            // busca index de variedad y crea objeto

            const foundIndexVariety = dataDaysState.varieties.findIndex(
              (element) => element.varietyValue === varietyName
            );

            const newObject = {
              variety: dataDaysState.varieties[foundIndexVariety].variety,
              varietyValue: varietyName,
              productLine:
                dataDaysState.varieties[foundIndexVariety]?.productLine || '',
              productLineValue:
                dataDaysState.varieties[foundIndexVariety]?.productLineValue ||
                '',
              date: dateKey,
              kilos: kilos,
              // cj: cj,
              // mexico: mexico,
              // quarantine: quarantine,
            };

            // Agrega el nuevo objeto al array
            newArray.push(newObject);
          }
        }
      }
    }

    if (!hasKilos) {
      setNoMessageKilosState(true);
    } else {
      setLoadingSubmitState(true);

      // const foundOrchard = filterDataResponseState.findIndex(
      //   (element) => element.value === orchard_filter
      // );

      // // console.log(filterDataResponseState[foundOrchard], '<<<<<------------');
      // const orchardData = filterDataResponseState[foundOrchard].label;
      // const orchardValueData = filterDataResponseState[foundOrchard].value;

      // console.log(infoFilterTab, '<<<<<----infoFilterTab----');

      // console.log(userId, '<<<<<----userId----', dataFilterState);
      // TODO: tenemos el productLine a nivel general, debería pasar a nivel de variedad/days

      const extraDataOrchard = dataFilterState.find(
        (ele) => ele.value === infoFilterTab?.orchard?.value
      );

      const dataObj = {
        userId: userId,
        client: userClient?.label || '',
        clientValue: userClient?.value || '',
        // orchard: orchardData,
        // orchardValue: orchardValueData,
        orchard: infoFilterTab?.orchard?.label,
        orchardValue: infoFilterTab?.orchard?.value,
        agroBusiness: infoFilterTab?.agroBusiness?.label || '',
        agroBusinessValue: infoFilterTab?.agroBusiness?.value || '',
        // TODO: technicBoss y Group (label y value)
        group: extraDataOrchard?.group || '',
        groupValue: extraDataOrchard?.groupValue || '',
        technicBoss: extraDataOrchard?.technicBoss || '',
        technicBossValue: extraDataOrchard?.technicBossValue || '',
        specie: infoFilterTab?.specie?.label || '',
        specieValue: infoFilterTab?.specie?.value || '',
        // productLine: infoFilterTab?.productionLine?.label || '',
        // productLineValue: infoFilterTab?.productionLine?.value || '',
        days: newArray,
      };

      // console.log(dataObj, '<<<<<----data obj--------', infoFilterTab);

      try {
        const response = await axiosContext.authAxios.post(
          endPointNamePost,
          dataObj
        );

        const { status } = response;

        if (status === 200) {
          // console.log(data, '<<<<<------------');
          setSuccessSubmitState(true);
          resetForm();
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoadingSubmitState(false);
      }
    }
  };

  const closeSnackHandler = () => {
    setSuccessSubmitState(false);
    setDataDaysState([]);
  };

  useEffect(() => {
    const menuTabs = tabItems.map((tab) => {
      return {
        ...tab,
        disabled: false,
      };
    });

    setTabsArrayState(menuTabs);
  }, [moduleUser]);

  function getFirstDateValue(objeto) {
    for (const fecha in objeto) {
      const valor = objeto[fecha].kilograms;
      if (valor && valor !== '0' && valor !== undefined) {
        let valueToString = valor.toString();
        valueToString = valueToString.replace('.', '');
        return { fecha, valor: parseFloat(valor) };
      }
    }
    return null;
  }

  const copyHandler = () => {
    // console.log('pasa por aca');
    const values = getValuesForm();
    // console.log(values, '<<<<<------------');

    const firstDateCol = dataDaysState.days[0].days[0];

    for (const varietyKey in values) {
      if (Object.prototype.hasOwnProperty.call(values, varietyKey)) {
        const varietyName = varietyKey.split('-')[1];
        const varietyDates = values[varietyKey];

        const firstDateWithValue =
          getFirstDateValue(varietyDates)?.fecha || firstDateCol;

        const varietyFirstValue =
          values[varietyKey][firstDateWithValue]?.kilograms;

        // console.log(varietyName, varietyFirstValue, '<<<<<----vv--------');

        if (varietyFirstValue) {
          const valueToCopy = varietyFirstValue;
          let valueToString = valueToCopy.toString();
          valueToString = valueToString.replace('.', '');

          // recorrer las fechas de varietyDates
          for (const dateKey in varietyDates) {
            // console.log(dateKey, '<<<<<----vv--------');
            if (Object.prototype.hasOwnProperty.call(varietyDates, dateKey)) {
              if (dateKey >= firstDateWithValue) {
                const dateValue = varietyDates[dateKey];

                if (!dateValue.kilograms) {
                  setValueForm(
                    `variety-${varietyName}.${dateKey}.kilograms`,
                    valueToString
                  );
                }
              }
            }
          }

          // setValueForm(
          //   `variety-${varietyName}.${'2023-10-17'}.kilograms`,
          //   valueToString
          // );
        }
      }
    }
  };

  const paramsHandler = () => {
    history.push({
      pathname: '/params',
    });
  };

  const resetFieldHandler = () => {
    // console.log('pasa por aca');
    const values = getValuesForm();
    // console.log(values, '<<<<<------------');

    // const firstDateCol = dataDaysState.days[0].days[0];

    for (const varietyKey in values) {
      if (Object.prototype.hasOwnProperty.call(values, varietyKey)) {
        const varietyName = varietyKey.split('-')[1];
        const varietyDates = values[varietyKey];

        // console.log(varietyName, varietyFirstValue, '<<<<<----vv--------');

        for (const dateKey in varietyDates) {
          if (Object.prototype.hasOwnProperty.call(varietyDates, dateKey)) {
            const dateValue = varietyDates[dateKey];

            if (dateValue.kilograms) {
              setValueForm(`variety-${varietyName}.${dateKey}.kilograms`, '');
            }
          }
        }
      }
    }
  };

  const resetFormHandler = () => {
    resetFieldHandler();
  };

  const resetFilters = () => {
    // console.log('reset filters');
    reset();
    setCopyButtonState(false);
    setSuccessSubmitState(false);
    setDataDaysState([]);
  };

  useEffect(() => {
    if (userClient && userClient?.value === 'Garces_Fruit_2') {
      // console.log(userClient, '<<<<<------------');
      setGarces2State(true);
    }
  }, [userClient]);

  // console.log(filterDataResponseState, '<<<<<------------');

  // drawer parameters

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleCloseToggle = () => {
    setOrchardSelectedState(null);
    setMobileOpen(false);
  };

  const handleOpenToggle = () => {
    if (useOrchard.value) {
      setOrchardSelectedState(useOrchard);
      setMobileOpen(true);
    }
  };

  const clearDataDays = () => {
    setDataDaysState([]);
  };

  const drawer = (
    <div>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={handleCloseToggle}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <Params
        closeDrawer={handleCloseToggle}
        orchardFilter={orchardSelectedState}
        clearDays={clearDataDays}
      />
    </div>
  );

  // funciones para hacer limpieza conjunta
  // en especie, linea de produccion y grupo
  const handleClearSpecieValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('specie', '');
      setValue('productionLine', '');
      setValue('variety', []);
    } else {
      setValue('specie', value);
    }
  };

  const handleClearProductLineValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('specie', '');
      setValue('productionLine', '');
      setValue('variety', []);
    } else {
      setValue('productionLine', value);
    }
  };

  const handleClearGroupValue = (value) => {
    console.log('clear value', value);
    if (!value) {
      setValue('group', '');
      setValue('agroBusiness', '');
      setValue('orchard', '');
    } else {
      setValue('group', value);
    }
  };

  return (
    <div className='form-style'>
      <Drawer
        container={container}
        variant='temporary'
        anchor='right'
        open={mobileOpen}
        onClose={handleCloseToggle}
        // onOpen={handleOpenToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Bi Semanal - Cosecha
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-12 align-items-center'>
                    <MobileFilters>
                      <form
                        className='form-inline'
                        autoComplete='on'
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className='input-group mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.technicBoss}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='technicBoss'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataTechnicBossState}
                            placeholder='Jefe Técnico'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.group}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='group'
                            isSearchable
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearGroupValue}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataGroupState}
                            placeholder='Grupo'
                          />
                        </div>

                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.agroBusiness}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='agroBusiness'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={agroBusinessOptionsState}
                            placeholder='Razón Social'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.orchard}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='orchard'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={filterOrchardState}
                            placeholder='Huerto'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.specie}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='specie'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            onChange={handleClearSpecieValue}
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={specieOptionsState}
                            placeholder='Especie'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.productionLine}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='productionLine'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearProductLineValue}
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={productionLineDataState}
                            placeholder='l. Producción'
                          />
                        </div>
                        {valueTabState === 0 ? (
                          <>
                            <div className='input-group ml-md-2 mb-3'>
                              <SelectMultipleWidget2
                                errors={errors.variety}
                                loading={
                                  loadingFiltersState || loadingVarietiesState
                                }
                                control={control}
                                menuPortalTarget={document.body}
                                labeltext=''
                                name='variety'
                                display='vertical'
                                variant='outlined'
                                isClearable={true}
                                isSearchable
                                isMulti={true}
                                req={false}
                                defaultValue=''
                                options={varietyOptionsState}
                                placeholder='Variedad'
                              />
                            </div>
                            <div className='input-group ml-md-2 mb-3'>
                              <TextFieldWidgetDateController2
                                name='since'
                                labeltext='Desde'
                                display='vertical'
                                variant='outlined'
                                control={control}
                                errors={errors.since}
                                format='dd-MM-yyyy'
                                defaultValue={subDays(new Date(), 1)}
                              />
                            </div>
                            <div className='input-group ml-md-2 mb-3'>
                              <TextFieldWidgetDateController2
                                name='until'
                                labeltext='Hasta'
                                format='dd-MM-yyyy'
                                display='vertical'
                                variant='outlined'
                                maxDate={maxDateValidationState}
                                control={control}
                                minDate={minDateState}
                                errors={errors.until}
                                // defaultValue={null}
                                defaultValue={new Date()}
                              />
                            </div>
                          </>
                        ) : null}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            paddingTop: 5,
                          }}
                        >
                          <SaveAction
                            title='Filtrar'
                            color='secondary'
                            size='large'
                            className='roundedButton mx-md-2'
                            variant='contained'
                            messageType='add'
                            mutationLoading={
                              loadingSubmitFilterState || excelLoadingState
                            }
                            success={false}
                          />
                          <Button
                            variant='outlined'
                            size='large'
                            onClick={resetFilters}
                          >
                            Limpiar
                          </Button>
                        </div>
                      </form>
                      {errors.incorrectDates && (
                        <p
                          style={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {errors.incorrectDates.message}
                        </p>
                      )}
                    </MobileFilters>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <>
          <>
            <BsRow class='no-gutters'>
              <BsCol class='col-md-12'>
                <Card elevation={0}>
                  <CardContent>
                    <SimpleTabs
                      tabitems={tabsArrayState}
                      value={valueTabState}
                      toggler={handleChangeTab}
                      tabEnabled={true}
                    >
                      <TabPanel value={valueTabState} index={0} py='py-3'>
                        <>
                          {noMessageKilosState ? (
                            <Alert
                              variant='filled'
                              severity='warning'
                              sx={{
                                fontSize: 16,
                                mb: 2,
                              }}
                            >
                              <AlertTitle
                                sx={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                Aviso
                              </AlertTitle>
                              No ha ingresado Kilos para Registrar
                            </Alert>
                          ) : null}
                          <form
                            autoComplete='false'
                            noValidate
                            onSubmit={handleSubmitForm(onSubmitForm)}
                          >
                            <FormPrompt hasUnsavedChanges={copyButtonState} />
                            {dataDaysState &&
                            dataDaysState?.days &&
                            dataDaysState?.days.length ? (
                              <>
                                <BsRow>
                                  <BsCol class='col-md-12 col-lg-9'>
                                    <div className='table-responsive'>
                                      <table className='table table-sm'>
                                        <tbody>
                                          <tr>
                                            {WEEK_DAYS.map((day, index) => {
                                              return (
                                                <React.Fragment key={day.value}>
                                                  <td
                                                    className={clsx(
                                                      classes.daysTableStyle
                                                    )}
                                                  >
                                                    <div>
                                                      {dataDaysState.daysActive[
                                                        day.value
                                                      ] ? (
                                                        <i className='fas fa-check-square ml-1'></i>
                                                      ) : (
                                                        <i className='fas fa-times ml-1'></i>
                                                      )}
                                                      {day.label}
                                                    </div>
                                                  </td>
                                                </React.Fragment>
                                              );
                                            })}
                                            <td
                                              style={{
                                                borderLeft: '1px solid #e3e3e3',
                                                paddingLeft: '20px',
                                              }}
                                            >
                                              <Tooltip title='Editar Parámetros'>
                                                <IconButton
                                                  aria-label='Editar'
                                                  color='primary'
                                                  // onClick={paramsHandler}
                                                  onClick={handleOpenToggle}
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </BsCol>
                                </BsRow>
                                <div className={classes.rowVarietiesStyle}>
                                  {dataDaysState.varieties.map((variety) => {
                                    return (
                                      <div
                                        key={variety.varietyValue}
                                        className='varietyBox'
                                      >
                                        <table className='table'>
                                          <tbody>
                                            <tr>
                                              <td
                                                colSpan={3}
                                                className='varietyTitleStyle'
                                              >
                                                <Typography variant='h5'>
                                                  {variety.variety}
                                                </Typography>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <Typography>
                                                  Pactados
                                                </Typography>
                                                {totalKilosPerVarietyState[
                                                  variety.varietyValue
                                                ] !== 0 &&
                                                totalKilosPerVarietyState[
                                                  variety.varietyValue
                                                ] !== undefined ? (
                                                  <Typography
                                                    variant='h5'
                                                    sx={{
                                                      fontWeight: 600,
                                                      color:
                                                        theme.palette.success
                                                          .main,
                                                    }}
                                                  >
                                                    {numberFormatDecimals(
                                                      variety.deliveredYield +
                                                        totalKilosPerVarietyState[
                                                          variety.varietyValue
                                                        ],
                                                      0
                                                    )}
                                                  </Typography>
                                                ) : (
                                                  <Typography variant='h5'>
                                                    {numberFormatDecimals(
                                                      variety.deliveredYield,
                                                      0
                                                    )}
                                                  </Typography>
                                                )}
                                              </td>
                                              <td>
                                                <Typography>
                                                  Est. Gruesa
                                                </Typography>
                                                <Typography variant='h5'>
                                                  {numberFormatDecimals(
                                                    variety.yieldTotal,
                                                    0
                                                  )}
                                                </Typography>
                                              </td>
                                              <td>
                                                <Typography>Saldo</Typography>
                                                {variety?.availableYield &&
                                                variety?.availableYield -
                                                  (totalKilosPerVarietyState[
                                                    variety.varietyValue
                                                  ] || 0) >=
                                                  0 ? (
                                                  <Typography variant='h5'>
                                                    {numberFormatDecimals(
                                                      invertNumber(
                                                        variety?.availableYield -
                                                          (totalKilosPerVarietyState[
                                                            variety.varietyValue
                                                          ] || 0)
                                                      ),
                                                      1
                                                    )}
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    variant='h5'
                                                    sx={{
                                                      fontWeight: 600,
                                                      color:
                                                        theme.palette.success
                                                          .main,
                                                    }}
                                                  >
                                                    {numberFormatDecimals(
                                                      invertNumber(
                                                        variety?.availableYield -
                                                          (totalKilosPerVarietyState[
                                                            variety.varietyValue
                                                          ] || 0)
                                                      ),
                                                      1
                                                    )}{' '}
                                                    {/* {variety?.availableYield}{' '}
                                                    {totalKilosPerVarietyState[
                                                      variety.varietyValue
                                                    ] || 0} */}
                                                  </Typography>
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    );
                                  })}
                                </div>
                                <BsRow>
                                  <BsCol class='col-md-12 mb-2 text-right'>
                                    <SimpleIconButton
                                      labelButton='Copiar primer Valor día/variedad'
                                      color='primary'
                                      size='small'
                                      disabled={!copyButtonState}
                                      actionOnClick={copyHandler}
                                    />
                                    <Button
                                      variant='outlined'
                                      color='primary'
                                      size='small'
                                      className='ml-2'
                                      disabled={!copyButtonState}
                                      onClick={resetFormHandler}
                                    >
                                      Limpiar Formulario
                                    </Button>
                                  </BsCol>
                                </BsRow>
                                <div className='table-responsive'>
                                  {dataDaysState.days.map((week) => {
                                    return (
                                      <BsRow key={week.week} class='mb-3'>
                                        <BsCol class='col-md-12'>
                                          <div
                                            className={
                                              classes.weekContainerTitleStyle
                                            }
                                          >
                                            <Typography
                                              variant='subtitle1'
                                              gutterBottom
                                              className={classes.weekTitleStyle}
                                              // color={'white'}
                                            >
                                              Semana {week.weekYear}
                                            </Typography>
                                          </div>
                                          {/* <hr /> */}
                                          <table
                                            className={clsx(
                                              'table table-sm',
                                              classes.tableStyle
                                            )}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  rowSpan={2}
                                                  style={{
                                                    minWidth: '120px',
                                                    verticalAlign: 'middle',
                                                  }}
                                                >
                                                  Variedad
                                                </th>
                                                {week.days.map((day, index) => {
                                                  const classDay =
                                                    index % 2 === 0
                                                      ? ''
                                                      : classes.OddColStyle;
                                                  return (
                                                    <React.Fragment key={day}>
                                                      <th
                                                        className={clsx(
                                                          classDay,
                                                          'text-center'
                                                        )}
                                                      >
                                                        {dateFrontConvert(day)}{' '}
                                                        <br />
                                                        {week.daysNames[index]}
                                                      </th>
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {dataDaysState.varieties.map(
                                                (variety) => {
                                                  return (
                                                    <tr
                                                      key={variety.varietyValue}
                                                    >
                                                      <td
                                                        className={
                                                          classes.varietyNameStyle
                                                        }
                                                      >
                                                        {variety.variety}
                                                      </td>
                                                      {week.days.map(
                                                        (day, index) => {
                                                          const classDay =
                                                            index % 2 === 0
                                                              ? ''
                                                              : classes.OddColStyle;

                                                          const dayValue =
                                                            week.values.find(
                                                              (ele) => {
                                                                return (
                                                                  ele.day ===
                                                                  day
                                                                );
                                                              }
                                                            );

                                                          const dayVarietyValue =
                                                            dayValue.values.find(
                                                              (ele) => {
                                                                return (
                                                                  ele.varietyValue ===
                                                                  variety.varietyValue
                                                                );
                                                              }
                                                            );

                                                          return (
                                                            <React.Fragment
                                                              key={day}
                                                            >
                                                              <td
                                                                className={clsx(
                                                                  classDay,
                                                                  'text-center'
                                                                )}
                                                              >
                                                                <Controller
                                                                  control={
                                                                    controlForm
                                                                  }
                                                                  rules={{
                                                                    required: false,
                                                                  }}
                                                                  name={`variety-${variety.varietyValue}.${day}.kilograms`}
                                                                  render={({
                                                                    field: {
                                                                      onChange,
                                                                      name,
                                                                      value,
                                                                    },
                                                                  }) => (
                                                                    <NumberFormat
                                                                      thousandsGroupStyle='thousand'
                                                                      placeholder={
                                                                        dayVarietyValue?.kilos?.toString() ||
                                                                        ''
                                                                      }
                                                                      value={
                                                                        value
                                                                      }
                                                                      onChange={
                                                                        onChange
                                                                      }
                                                                      error={Boolean(
                                                                        errorsForm.kilograms
                                                                      )}
                                                                      helperText={
                                                                        errorsForm.kilograms
                                                                          ? 'Complete cantidad'
                                                                          : ''
                                                                      }
                                                                      FormHelperTextProps={{
                                                                        classes:
                                                                          {
                                                                            root: classes.rootHelper,
                                                                          },
                                                                      }}
                                                                      variant='standard'
                                                                      InputProps={{
                                                                        classes:
                                                                          {
                                                                            input:
                                                                              classes.inputText,
                                                                          },
                                                                        size: 'small',
                                                                        endAdornment:
                                                                          (
                                                                            <>
                                                                              {value && (
                                                                                <IconButton
                                                                                  color='primary'
                                                                                  aria-label='clear'
                                                                                  component='span'
                                                                                  onClick={() =>
                                                                                    onReset(
                                                                                      `variety-${variety.varietyValue}.${day}.kilograms`
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <ClearIcon
                                                                                    fontSize='small'
                                                                                    style={{
                                                                                      color:
                                                                                        theme
                                                                                          .palette
                                                                                          .grey[400],
                                                                                    }}
                                                                                  />
                                                                                </IconButton>
                                                                              )}
                                                                              <InputAdornment
                                                                                position='end'
                                                                                variant='filled'
                                                                                disableTypography
                                                                                style={{
                                                                                  color:
                                                                                    'black',
                                                                                  height:
                                                                                    'auto',
                                                                                  maxHeight:
                                                                                    '40px',
                                                                                  padding:
                                                                                    '14px 16px',
                                                                                  borderRadius:
                                                                                    theme
                                                                                      .shape
                                                                                      .borderRadius,
                                                                                  backgroundColor:
                                                                                    theme
                                                                                      .palette
                                                                                      .background
                                                                                      .default,
                                                                                }}
                                                                              >
                                                                                Kg
                                                                              </InputAdornment>
                                                                            </>
                                                                          ),
                                                                      }}
                                                                      customInput={
                                                                        TextField
                                                                      }
                                                                      className='flex-grow-1'
                                                                      // prefix="$"
                                                                      decimalSeparator=','
                                                                      displayType='input'
                                                                      type='text'
                                                                      thousandSeparator='.'
                                                                      allowNegative={
                                                                        false
                                                                      }
                                                                      decimalScale={
                                                                        2
                                                                      }
                                                                    />
                                                                  )}
                                                                />
                                                                {dayVarietyValue?.kilos ? (
                                                                  <Tooltip
                                                                    title='Dejar día en 0'
                                                                    placement='right'
                                                                  >
                                                                    <IconButton
                                                                      aria-label='delete'
                                                                      size='small'
                                                                      color='#FFFFFF'
                                                                      sx={{
                                                                        backgroundColor:
                                                                          theme
                                                                            .palette
                                                                            .warning
                                                                            .main,
                                                                        boxShadow: 1,
                                                                        // marginTop:
                                                                        //   -10,
                                                                      }}
                                                                      onClick={() =>
                                                                        onClearDayValue(
                                                                          `variety-${variety.varietyValue}.${day}.kilograms`
                                                                        )
                                                                      }
                                                                    >
                                                                      <ClearIcon
                                                                        sx={{
                                                                          fontWeight: 900,
                                                                          fontSize: 16,
                                                                        }}
                                                                      />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                ) : null}
                                                              </td>
                                                            </React.Fragment>
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                          {/* </div> */}
                                        </BsCol>
                                      </BsRow>
                                    );
                                  })}
                                </div>
                                <BsRow class='justify-content-center'>
                                  <BsCol class='col-12 col-md-3 mt-4'>
                                    <SaveAction
                                      title='Guardar'
                                      color='primary'
                                      size='large'
                                      fullWidth={true}
                                      actionClose={closeSnackHandler}
                                      disabled={false}
                                      className='btn-block'
                                      messageType='add'
                                      mutationLoading={loadingSubmitState}
                                      success={successSubmitState}
                                    />
                                  </BsCol>
                                </BsRow>
                              </>
                            ) : (
                              <BsRow>
                                <BsCol class='col-md-12'>
                                  <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={classes.subtitleDash}
                                  >
                                    Filtrar para cargar formulario
                                  </Typography>
                                </BsCol>
                              </BsRow>
                            )}
                          </form>
                          {loadingSubmitFilterState && <LoadingWidget />}
                        </>
                      </TabPanel>
                      <TabPanel value={valueTabState} index={1}>
                        <TabCertification filter={infoFilterTab} />
                      </TabPanel>
                    </SimpleTabs>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </>
        </>
      </BsContainer>
    </div>
  );
};

export default FormSchedule;
