import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import _, { filter, head } from 'lodash';
import { useHistory } from 'react-router-dom';
import { subDays, addDays } from 'date-fns';
import { useErrorHandler } from 'react-error-boundary';
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useForm, useWatch, Controller, Form } from 'react-hook-form';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';

import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  LoadingWidget,
  SaveAction,
  SimpleIconButton,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
  ButtonAction,
} from '../../../components';
import { FiltersContext } from '../../../context';
import {
  alphabeticalSort,
  dateFormatIso,
  dateFrontConvert,
  getOrchardVarietiesDeep,
  getUniqueValuesMaster,
  numberFormatDecimals,
} from '../../../helpers';
import { FormPrompt } from '../../../helpers/FormPrompt';
import { MobileFilters } from 'containers/Library';
// import customPanel from './CustomPanel';
import './styles.css';
import MOCKDATA from './data_weeks.json';

const drawerWidth = '40vw';

const useStyles = makeStyles((theme) => ({
  tableAreaStyle: {
    maxHeight: '370px',
    overflow: 'auto',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperContainerStyle: {
    padding: 20,
    marginBottom: 15,
  },
  dashCard: {
    minHeight: '100%',
  },
  titleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
  },
  subtitleDash: {
    fontSize: 18,
    fontWeight: 600 + ' !important',
    marginBottom: '15px !important',
    marginTop: '20px !important',
  },
  weekTitleStyle: {
    fontSize: '18px !important',
    fontWeight: 600 + ' !important',
    // marginTop: '20px !important',
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    maxWidth: '90px !important',
    minWidth: '70px !important',
  },
  dividerRow: {
    marginTop: 30,
  },
  iconText: {
    marginRight: 10,
  },
  OddColStyle: {
    backgroundColor: 'rgba(227,235,237,.3)',
  },
  tableStyle: {
    '& tbody tr:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& tbody tr td': {
      borderTop: 'none !important',
    },
  },
  varietyNameStyle: {
    fontWeight: 600,
    fontSize: 15,
    paddingTop: 10 + 'px !important',
    width: '120px',
  },
  legendStyle: {
    position: 'absolute',
    right: 0,
    top: 10,
    // media query para pantallas menores a 768px
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      marginBottom: 10,
    },
  },
  daysTableStyle: {
    // borderRight: '1px solid #e3e3e3',
    // borderBottom: '1px solid #e3e3e3',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: '8px',
      padding: '0px 10px',
      '& i': {
        fontSize: '17px',
        marginRight: '9px',
      },

      '& i.fa-check-square': {
        color: theme.palette.success.main,
      },
    },
  },
  rowVarietiesStyle: {
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& .varietyBox': {
      paddingLeft: '5px',
      paddingRight: '5px',
      width: '408px',
      backgroundColor: 'rgba(245,245,245,.4)',
      marginRight: '8px',
      marginBottom: '5px',
      borderTop: '3px solid',
      borderTopColor: theme.palette.primary.main,
    },
    '& .varietyBox table': {
      marginBottom: '0px',
    },
    // '& .varietyBox table td:not(:first-child)': {
    // '& .varietyBox table td': {
    //   textAlign: 'center',
    // },
    '& .varietyBox table td': {
      width: '25%',
      overflow: 'hidden',
      wordBreak: 'break-all',
      whiteSpace: 'normal',
      textAlign: 'center',
    },
    // '& .varietyBox table td:nth-child(1)': {
    //   width: '110px',
    //   overflow: 'hidden',
    //   wordBreak: 'break-all',
    //   whiteSpace: 'normal',
    // },
    '& .varietyBox table td.varietyTitleStyle': {
      textAlign: 'center',
      padding: '5px 0px',
    },
    '& .varietyBox table td:nth-child(1)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td:nth-child(2)': {
      borderRight: '1px solid #e3e3e3',
    },
    '& .varietyBox table td p': {
      fontSize: '12px',
    },
  },
  weekContainerTitleStyle: {
    backgroundColor: '#d9eef4',
    padding: '5px 8px',
    '& h6': {
      // color: 'white',
      padding: '5px 0px',
      marginBottom: '0px',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
}));

/**
 * Inverts a number.
 *
 * @param {number} number - The number to be inverted.
 * @returns {number} The inverted number.
 */
const invertNumber = (number = 0) => {
  return number === 0 ? number : number * -1;
};

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((item) => {
    item.specie.forEach((specie) => {
      specie.productLine.forEach((productLine) => {
        productLine.variety.forEach((variety) => {
          result.push({
            orchard: {
              label: item.label,
              value: item.value,
            },
            agroBusiness: {
              label: item.agroBusiness,
              value: item.agroBusinessValue,
            },
            group: {
              label: item.group,
              value: item.groupValue,
            },
            technicBoss: {
              label: item.technicBoss,
              value: item.technicBossValue,
            },
            specie: {
              label: specie.label,
              value: specie.value,
            },
            productLine: {
              label: productLine.label,
              value: productLine.value,
            },
            variety: {
              label: variety.label,
              value: variety.value,
            },
          });
        });
      });
    });
  });

  return result;
}

const endPointName = 'v1/planningHarvest/calendarization';
const endPointNamePost = 'v1/planningHarvest';
const endPointFilters = 'v1/libraryExternal/specie';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  ClientSideRowModelModule,
  RangeSelectionModule,
  MenuModule,
  MultiFilterModule,
  MasterDetailModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
]);

const arrayColsResume = ['deliveredYield', 'availableYield', 'balance'];

const FormSchedule = (props) => {
  const theme = useTheme();

  const classes = useStyles();

  const history = useHistory();

  const { window } = props;

  const axiosContext = useContext(AxiosContext);

  const {
    moduleUser,
    userClient,
    userOrchard,
    userId,
    userFullname,
    userIdProfile,
  } = useContext(Context);

  const [garces2State, setGarces2State] = useState(false);

  const errorHandler = useErrorHandler();

  // crear un ref de alert
  const alertRef = useRef(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const {
    state: { userOrchard_filter, orchard_filter, orchard_id_filter },
    addFilter,
  } = useContext(FiltersContext);

  const moduleOrchardFilter = 'statusOrchardPlanning';

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [agroBusinessOptionsState, setAgroBusinessOptionsState] = useState([]);

  const [specieOptionsState, setSpecieOptionsState] = useState([]);

  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const [filterDataTechnicBossState, setFilterDataTechnicBossState] = useState(
    []
  );

  const [filterDataGroupState, setFilterDataGroupState] = useState([]);

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  // const [filterOrchardState, setFilterOrchardState] = useState(
  //   userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  // );

  const [filterOrchardState, setFilterOrchardState] = useState([]);

  const [fullDataOrchardState, setFullDataOrchardState] = useState([]);

  const [dataFilterState, setDataFilterState] = useState([]);

  const [filterDataAgroBusinessState, setFilterDataAgroBusinessState] =
    useState([]);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [specieDataState, setSpecieDataState] = useState([]);

  const [productionLineDataState, setProductionLineDataState] = useState([]);

  const [varietyDataState, setVarietyDataState] = useState([]);
  const [varietyFullDataState, setVarietyFullDataState] = useState([]);

  const [loadingVarietiesState, setLoadingVarietiesState] = useState(false);

  const [openDialogState, setOpenDialogState] = useState(false);

  // dates

  const actualYear = new Date().getFullYear();

  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const [minDateValidationState, setMinDateValidationState] = useState(
    new Date(`${actualYear}-01-01`)
  );
  const [maxDateValidationState, setMaxDateValidationState] = useState(
    new Date(`${actualYear}-12-31`)
  );

  const [minDateState, setminDateState] = useState(null);

  const [loadingSubmitFilterState, setLoadingSubmitFilterState] =
    useState(false);

  const [loadingSubmitState, setLoadingSubmitState] = useState(false);

  const [noMessageKilosState, setNoMessageKilosState] = useState(false);

  const [successSubmitState, setSuccessSubmitState] = useState(false);

  const [totalKilosPerVarietyState, setTotalKilosPerVarietyState] = useState(
    {}
  );

  const [copyButtonState, setCopyButtonState] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const [orchardSelectedState, setOrchardSelectedState] = useState(null);

  const [excelLoadingState, setExcelLoadingState] = useState(false);

  const [excelSuccessDownloadState, setExcelSuccessDownloadState] =
    useState(false);

  const [styleState, setStyleState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
  });

  const [fullScreenState, setFullScreenState] = useState(false);

  const [totalDataKilosState, setTotalDataKilosState] = useState(0);

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);
  const gridApiRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [totalRow, setTotalRow] = useState({});
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const [quickFilterText, setQuickFilterText] = useState(''); // estado para el filtro de busquedageneral

  const [customGroupColsState, setCustomGroupColsState] = useState('0');
  const [detailColumnsState, setDetailColumnsState] = useState([]);

  // crear un ref para mantener el custom actual
  const customGroupColsRef = useRef('0');

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  //-------------- FUNCIONES PARA LA TABLA

  // funcion para formatear los números
  const numberFormatter = (value) => {
    if (value === undefined || value === null) {
      return '';
    }
    return `${value.toLocaleString().replace(/,/g, '.')}`;
  };

  // suma de totales
  const sumTotalWeeks = (rowData) => {
    return rowData.reduce((total, row) => {
      return total + (row.totalWeeks || 0);
    }, 0);
  };

  // funcion para limpiar la selección de celdas
  const clearFocusSelection = () => {
    if (gridApiRef.current && gridApiRef.current) {
      gridApiRef.current.clearFocusedCell();
    }
  };

  // genera las columnas de totales de semana
  const generateTotalColumns = (data) => {
    return data.map((weekData) => {
      const weekNumberName = weekData.weekYear;

      return {
        colId: `totalWeek_${weekNumberName}`,
        headerName: `Total Semana ${weekNumberName}`,
        field: `totalWeek_${weekNumberName}`,
        cellDataType: 'number',
        suppressFillHandle: true,
        // valueFormatter: (params) => numberFormatter(params.value),
        valueGetter: (params) => {
          return weekData.daysNamesValues.reduce((total, dayName) => {
            const field = `day_${dayName.toLowerCase()}_${weekNumberName}`;
            return total + (params.data[field] || 0);
          }, 0);
        },
        cellRenderer: (params) => numberFormatter(params.value),
        editable: false,
        cellStyle: { backgroundColor: '#FFF1D3' },
      };
    });
  };

  // genera la columna de total general
  const generateOverallTotalColumn = (data) => {
    return {
      headerName: 'Total Semanas',
      field: `totalWeeks`,
      cellDataType: 'number',
      suppressFillHandle: true,
      valueGetter: (params) => {
        return data.reduce((total, weekData) => {
          return (
            total +
            weekData.daysNamesValues.reduce((weekTotal, dayName) => {
              const field = `day_${dayName.toLowerCase()}_${weekData.weekYear}`;
              return weekTotal + (params.data[field] || 0);
            }, 0)
          );
        }, 0);
      },
      cellRenderer: (params) => numberFormatter(params.value),
      editable: false,
      cellStyle: (params) => {
        return { backgroundColor: '#FFF1D3' };
      },
    };
  };

  // funcion para edición de celdas
  const onCellValueChanged = (params) => {
    const { data, colDef, newValue, oldValue } = params;
    const { field } = colDef;

    if (newValue === null || newValue === '') {
      data[`edited_${colDef.field}`] = false; // Marca la celda como editada
      params.node.setDataValue(colDef.field, oldValue); // Restaura el valor anterior
    } else {
      if (newValue !== oldValue && newValue !== '') {
        data[`edited_${colDef.field}`] = true; // Marca la celda como editada
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [colDef.field],
          force: true,
        });
      }

      alertRef.current = true;
      setCopyButtonState(true);

      // Recalcular totales de la semana

      const weekYearKeys = Object.keys(data)
        .filter((key) => key.startsWith('totalWeek_'))
        .map((key) => key.split('_')[1]);

      // console.log(weekYearKeys, '<<<<<---weekYearKeys');

      // Recalcular los totales de cada semana
      weekYearKeys.forEach((weekYear) => {
        const totalWeekKey = `totalWeek_${weekYear}`;
        data[totalWeekKey] = 0;

        for (let key in data) {
          if (key.endsWith(`_${weekYear}`) && key.startsWith('day_')) {
            data[totalWeekKey] += data[key] || 0;
          }
        }
      });

      // Recalcular total general
      data['totalWeeks'] = Object.keys(data).reduce((sum, key) => {
        if (key.startsWith('totalWeek_')) {
          return sum + (data[key] || 0);
        }
        return sum;
      }, 0);

      // Actualizar el estado de rowData
      const updatedRowData = [...rowData];
      const rowIndex = updatedRowData.findIndex((row) => row.id === data.id);
      updatedRowData[rowIndex] = data;

      setTotalDataKilosState(sumTotalWeeks(updatedRowData));

      setRowData(updatedRowData);
    }
  };

  // procesa los datos y genera las filas
  // const processDataRowFull = (data) => {
  //   const { days, varieties } = data;

  //   const rows = [];

  //   varieties.forEach((variety) => {
  //     const row = {
  //       id: `${variety.varietyValue}_${variety.productLineValue}_${variety.groupValue}_${variety.specieValue}_${variety.orchardValue}_${variety.csg}_${variety.clientValue}`,
  //       varietyValue: variety.varietyValue,
  //       groupValue: variety.groupValue,
  //       agroBusinessValue: variety.agroBusinessValue,
  //       productLineValue: variety.productLineValue,
  //       specieValue: variety.specieValue,
  //       orchardValue: variety.orchardValue,
  //       csg: variety.csg,
  //       season: variety?.season,
  //       clientValue: variety.clientValue,
  //       technicBossValue: variety.technicBossValue,
  //       variety: variety.variety,
  //       group: variety.group,
  //       productLine: variety.productLine,
  //       specie: variety.specie,
  //       orchard: variety.orchard,
  //       agroBusiness: variety.agroBusiness,
  //       technicBoss: variety.technicBoss,
  //       deliveredYield: variety.deliveredYield,
  //       availableYield: variety.availableYield,
  //       yieldTotal: variety.yieldTotal,
  //       variantDetails: [
  //         {
  //           available: 60000,
  //           yieldTotal: 2000,
  //           year: '2024',
  //         },
  //         {
  //           available: 60000,
  //           yieldTotal: 4000,
  //           year: '2024',
  //         },
  //       ],
  //     };

  //     days.forEach((week, weekIndex) => {
  //       week.daysNamesValues.forEach((dayName, dayIndex) => {
  //         const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;
  //         row[key] =
  //           week.values[dayIndex].values.find(
  //             (v) =>
  //               v.varietyValue === variety.varietyValue &&
  //               v.productLineValue === variety.productLineValue &&
  //               v.groupValue === variety.groupValue &&
  //               v.specieValue === variety.specieValue &&
  //               v.orchardValue === variety.orchardValue &&
  //               v.csg === variety.csg &&
  //               v.clientValue === variety.clientValue &&
  //               v.technicBossValue === variety.technicBossValue &&
  //               v.season === variety.season
  //           )?.kilos || 0;
  //       });

  //       // Calcular total de la semana
  //       const totalWeekKey = `totalWeek_${week.weekYear}`;
  //       row[totalWeekKey] = week.daysNamesValues.reduce((sum, dayName) => {
  //         const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;
  //         return sum + (row[key] || 0);
  //       }, 0);
  //     });

  //     // Calcular total general
  //     row['totalWeeks'] = days.reduce((sum, week) => {
  //       const totalWeekKey = `totalWeek_${week.weekYear}`;
  //       return sum + (row[totalWeekKey] || 0);
  //     }, 0);

  //     rows.push(row);
  //   });

  //   console.log(rows, '<<<<<--------------rows');

  //   return rows;
  // };

  // obtiene los season de la unidad en days
  // NO SE OCUPA
  // function getSeasonsfromDays(days, unit) {
  //   const seasons = new Set();

  //   days.forEach((week) => {
  //     week.values.forEach((day) => {
  //       day.values.forEach((entry) => {
  //         if (
  //           entry.agroBusinessValue === unit.agroBusinessValue &&
  //           entry.orchardValue === unit.orchardValue &&
  //           entry.csg === unit.csg &&
  //           entry.varietyValue === unit.varietyValue &&
  //           entry.technicBossValue === unit.technicBossValue &&
  //           entry.specieValue === unit.specieValue &&
  //           entry.groupValue === unit.groupValue &&
  //           entry.productLineValue === unit.productLineValue
  //         ) {
  //           seasons.add(entry.season);
  //         }
  //       });
  //     });
  //   });

  //   return Array.from(seasons);
  // }

  // procesa las filas con su detalle histórico
  const processDataRowDetailFull = (data) => {
    const { days, varieties } = data;

    // console.log(days, '<<<<<---days');

    const varietiesActual = varieties.filter(
      (variety) => variety.season === new Date().getFullYear().toString()
    );

    const rows = [];

    varietiesActual.forEach((variety) => {
      // filtro de registros iguales de otras temporadas

      const pastVariety = varieties.filter(
        (v) =>
          v.varietyValue === variety.varietyValue &&
          v.productLineValue === variety.productLineValue &&
          v.groupValue === variety.groupValue &&
          v.specieValue === variety.specieValue &&
          v.orchardValue === variety.orchardValue &&
          v.csg === variety.csg &&
          v.clientValue === variety.clientValue &&
          v.technicBossValue === variety.technicBossValue &&
          v.season !== variety.season
      );

      // ordenar pastVariety por temporada descendente
      pastVariety.sort((a, b) => b.season - a.season);

      const row = {
        id: `${variety.varietyValue}_${variety.productLineValue}_${variety.groupValue}_${variety.specieValue}_${variety.orchardValue}_${variety.csg}_${variety.clientValue}`,
        varietyValue: variety.varietyValue,
        groupValue: variety.groupValue,
        agroBusinessValue: variety.agroBusinessValue,
        productLineValue: variety.productLineValue,
        specieValue: variety.specieValue,
        orchardValue: variety.orchardValue,
        csg: variety.csg,
        season: variety?.season,
        clientValue: variety.clientValue,
        technicBossValue: variety.technicBossValue,
        variety: variety.variety,
        group: variety.group,
        productLine: variety.productLine,
        specie: variety.specie,
        orchard: variety.orchard,
        agroBusiness: variety.agroBusiness,
        technicBoss: variety.technicBoss,
        deliveredYield: variety.deliveredYield,
        availableYield: variety.availableYield,
        yieldTotal: variety.yieldTotal,
        variantDetails: [],
      };

      days.forEach((week, weekIndex) => {
        week.daysNamesValues.forEach((dayName, dayIndex) => {
          const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;

          console.log(key, '<<<<<------------key');

          row[key] =
            week.values[dayIndex].values.find(
              (v) =>
                v.varietyValue === variety.varietyValue &&
                v.productLineValue === variety.productLineValue &&
                v.groupValue === variety.groupValue &&
                v.specieValue === variety.specieValue &&
                v.orchardValue === variety.orchardValue &&
                v.csg === variety.csg &&
                v.clientValue === variety.clientValue &&
                v.technicBossValue === variety.technicBossValue &&
                v.season === variety.season
            )?.kilos || 0;
        });

        // Calcular total de la semana
        const totalWeekKey = `totalWeek_${week.weekYear}`;
        row[totalWeekKey] = week.daysNamesValues.reduce((sum, dayName) => {
          const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;
          return sum + (row[key] || 0);
        }, 0);
      });

      // Calcular total general
      row['totalWeeks'] = days.reduce((sum, week) => {
        const totalWeekKey = `totalWeek_${week.weekYear}`;
        return sum + (row[totalWeekKey] || 0);
      }, 0);

      // const historySeasonsUnit = getSeasonsfromDays(days, {
      //   agroBusinessValue: variety.agroBusinessValue,
      //   orchardValue: variety.orchardValue,
      //   csg: variety.csg,
      //   varietyValue: variety.varietyValue,
      //   technicBossValue: variety.technicBossValue,
      //   specieValue: variety.specieValue,
      //   groupValue: variety.groupValue,
      //   productLineValue: variety.productLineValue,
      // });

      // console.log('------------------------->seasons', historySeasonsUnit);

      // for each pastVariety add to variantDetails
      if (pastVariety.length > 0) {
        pastVariety.forEach((pastVariety) => {
          const innerRow = {
            id: `${pastVariety.varietyValue}_${pastVariety.productLineValue}_${pastVariety.groupValue}_${pastVariety.specieValue}_${pastVariety.orchardValue}_${pastVariety.csg}_${pastVariety.clientValue}`,
            varietyValue: pastVariety.varietyValue,
            groupValue: pastVariety.groupValue,
            agroBusinessValue: pastVariety.agroBusinessValue,
            productLineValue: pastVariety.productLineValue,
            specieValue: pastVariety.specieValue,
            orchardValue: pastVariety.orchardValue,
            csg: pastVariety.csg,
            season: pastVariety?.season,
            clientValue: pastVariety.clientValue,
            technicBossValue: pastVariety.technicBossValue,
            variety: pastVariety.variety,
            group: pastVariety.group,
            productLine: pastVariety.productLine,
            specie: pastVariety.specie,
            orchard: pastVariety.orchard,
            agroBusiness: pastVariety.agroBusiness,
            technicBoss: pastVariety.technicBoss,
            deliveredYield: pastVariety.deliveredYield,
            availableYield: pastVariety.availableYield,
            yieldTotal: pastVariety.yieldTotal,
          };

          days.forEach((week, weekIndex) => {
            week.daysNamesValues.forEach((dayName, dayIndex) => {
              const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;

              innerRow[key] =
                week.values[dayIndex].values.find(
                  (v) =>
                    v.varietyValue === pastVariety.varietyValue &&
                    v.productLineValue === pastVariety.productLineValue &&
                    v.groupValue === pastVariety.groupValue &&
                    v.specieValue === pastVariety.specieValue &&
                    v.orchardValue === pastVariety.orchardValue &&
                    v.csg === pastVariety.csg &&
                    v.clientValue === pastVariety.clientValue &&
                    v.technicBossValue === pastVariety.technicBossValue &&
                    v.season === pastVariety.season
                )?.kilos || 0;
            });

            // Calcular total de la semana
            const totalWeekKey = `totalWeek_${week.weekYear}`;
            innerRow[totalWeekKey] = week.daysNamesValues.reduce(
              (sum, dayName) => {
                const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;
                return sum + (innerRow[key] || 0);
              },
              0
            );
          });

          // Calcular total general
          innerRow['totalWeeks'] = days.reduce((sum, week) => {
            const totalWeekKey = `totalWeek_${week.weekYear}`;
            return sum + (innerRow[totalWeekKey] || 0);
          }, 0);

          // hace el push a row de innerRow
          row.variantDetails.push(innerRow);
        });
      }

      rows.push(row);
    });

    console.log(rows, '<<<<<--------------rows');

    return rows;
  };

  // procesa los datos y genera las columnas
  const processDataColumns = (data) => {
    const weeks = data.map((weekData) => {
      const week = {
        // headerGroup: `week${weekData.week}`,
        colId: `week${weekData.week}`,
        headerName: `Semana ${weekData.weekYear}`,
        children: [],
      };

      weekData.days.forEach((day, index) => {
        const dayName = weekData.daysNames[index];
        const daysNamesValues = weekData.daysNamesValues[index];
        // console.log(day, '<<<<<---day formato');
        // const formattedDate = new Date(day).toLocaleDateString('es-ES', {
        //   day: '2-digit',
        //   month: '2-digit',
        //   year: '2-digit',
        // });

        // create  function that convert "2024-07-25" en "25/07/24"

        const child = {
          headerComponent: ({ header }) => (
            <div className='text-center'>
              {dayName}
              <br />
              {dateFrontConvert(day)}
            </div>
          ),
          headerName: `${dateFrontConvert(day)}`,
          field: `day_${daysNamesValues.toLowerCase()}_${weekData.weekYear}`,
          editable: true,
          cellDataType: 'number',
          cellClassRules: {
            'edited-cell': (params) =>
              params.data[`edited_${params.colDef.field}`],
          },
          valueFormatter: (params) => numberFormatter(params.value),
          // pintar celda condicional
          // cellStyle: (params) => {
          //   console.log(params, '<<<<<-------------------params');
          //   if (params.value === 25) {
          //     //mark police cells as red
          //     return { color: 'red', backgroundColor: 'green' };
          //   }
          //   return null;
          // },
        };

        week.children.push(child);
      });

      return week;
    });

    const totalColumns = generateTotalColumns(data);

    const overallTotalColumn = generateOverallTotalColumn(data);

    const finalColumns = [
      {
        headerName: '',
        colId: 'zero',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
        suppressMovable: true,
        width: 50,
        maxWidth: 50,
        sortable: false,
        suppressFillHandle: true,
        cellRenderer: 'agGroupCellRenderer',
        filter: false,
      },
      {
        colId: 'caracter',
        // headerGroup: 'caracter',
        headerName: 'Caracterización',
        children: [
          {
            headerName: 'Jefe tecnico',
            field: 'technicBoss',
            colId: 'technicBoss',
            editable: false,
            suppressFillHandle: true,
            suppressMovable: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },

          {
            headerName: 'CSG',
            field: 'csg',
            colId: 'csg',
            editable: false,
            suppressFillHandle: true,
            suppressMovable: true,
            filter: 'agMultiColumnFilter',
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },
          {
            headerName: 'Especie',
            field: 'specie',
            editable: false,
            suppressFillHandle: true,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },
          {
            headerName: 'Grupo',
            field: 'group',
            editable: false,
            suppressFillHandle: true,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },
          {
            headerName: 'Agricola',
            field: 'agroBusiness',
            editable: false,
            suppressFillHandle: true,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },
          {
            headerName: 'Huerto',
            field: 'orchard',
            editable: false,
            suppressFillHandle: true,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
          },

          {
            headerName: 'Línea de Producción',
            field: 'productLine',
            editable: false,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
            suppressFillHandle: true,
          },
          {
            headerName: 'Variedad',
            field: 'variety',
            editable: false,
            filter: 'agMultiColumnFilter',
            suppressMovable: true,
            filterParams: {
              filters: [
                {
                  filter: 'agTextColumnFilter',
                  filterParams: {
                    defaultOption: 'contains',
                    filterOptions: ['contains'],
                  },
                },
                {
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    suppressMiniFilter: true,
                  },
                },
              ],
            },
            suppressFillHandle: true,
          },
        ],
      },
      {
        colId: 'Resumen',
        headerName: 'Resumen',
        children: [
          {
            headerName: 'Entregado',
            field: 'deliveredYield',
            colId: 'deliveredYield',
            editable: false,
            filter: false,
            cellDataType: 'number',
            suppressFillHandle: true,
            valueFormatter: (params) => numberFormatter(params.value),
            valueGetter: (params) => params.data.totalWeeks || 0,
          },
          {
            headerName: 'Disponible',
            field: 'availableYield',
            colId: 'availableYield',
            editable: false,
            filter: false,
            cellDataType: 'number',
            suppressFillHandle: true,
            valueFormatter: (params) => numberFormatter(params.value),
          },
          {
            headerName: 'Saldo',
            colId: 'balance',
            editable: false,
            filter: false,
            cellDataType: 'number',
            suppressFillHandle: true,
            cellStyle: (params) => {
              if (params.value <= 0) {
                //mark police cells as red
                return { color: '#203543', backgroundColor: '#EBEEF1' };
              }
              return null;
            },
            valueFormatter: (params) => numberFormatter(Math.abs(params.value)),
            valueGetter: (params) =>
              params.data.availableYield - params.data.totalWeeks,
          },
        ],
      },
      ...weeks,
      {
        colId: 'totales',
        // headerGroup: 'caracter',
        headerName: 'Totales',
        children: [...totalColumns, overallTotalColumn],
      },
    ];

    return finalColumns;
  };

  // genera las columnas de la detalle
  const processDataDetailColumn = (data) => {
    const daysColumns = [];

    data.forEach((weekData) => {
      weekData.days.forEach((day, index) => {
        const dayName = weekData.daysNames[index];
        const daysNamesValues = weekData.daysNamesValues[index];

        const child = {
          headerComponent: ({ header }) => (
            <div className='text-center'>{dateFrontConvert(day)}</div>
          ),
          headerName: `${dateFrontConvert(day)}`,
          field: `day_${daysNamesValues.toLowerCase()}_${weekData.weekYear}`,
          editable: false,
          cellDataType: 'number',
          cellClassRules: {
            'edited-cell': (params) =>
              params.data[`edited_${params.colDef.field}`],
          },
          valueFormatter: (params) => numberFormatter(params.value),
        };

        daysColumns.push(child);
      });
    });

    const totalColumns = generateTotalColumns(data);

    const overallTotalColumn = generateOverallTotalColumn(data);

    const finalDetailColumns = [
      {
        headerName: '',
        colId: 'zero',
        width: 50,
        maxWidth: 50,
        editable: false,
        suppressHeaderMenuButton: true,
        sortable: false,
        suppressFillHandle: true,
        filter: false,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
        suppressHeaderFocus: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: '',
        field: '',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: 'Temporada',
        field: 'season',
        editable: false,
        suppressFillHandle: true,
      },
      {
        headerName: 'Entregado',
        field: 'deliveredYield',
        editable: false,
        filter: false,
        cellDataType: 'number',
        suppressFillHandle: true,
        valueFormatter: (params) => numberFormatter(params.value),
        valueGetter: (params) => {
          console.log(params.data, '<<<<<-------------params.data');
          return params.data.deliveredYield + params.data.totalWeeks || 0;
        },
      },
      {
        headerName: 'Disponible',
        field: 'availableYield',
        editable: false,
        filter: false,
        cellDataType: 'number',
        suppressFillHandle: true,
        valueFormatter: (params) => numberFormatter(params.value),
      },
      {
        headerName: 'Saldo',
        colId: 'yieldTotal',
        field: 'yieldTotal',
        editable: false,
        filter: false,
        cellDataType: 'number',
        suppressFillHandle: true,
        cellStyle: (params) => {
          if (params.value <= 0) {
            return { color: '#203543', backgroundColor: '#EBEEF1' };
          }
          return null;
        },
        valueFormatter: (params) => numberFormatter(Math.abs(params.value)),
        valueGetter: (params) =>
          params.data.availableYield -
          (params.data.deliveredYield + params.data.totalWeeks || 0),
      },
      ...daysColumns,
      ...totalColumns,
      overallTotalColumn,
    ];
    return finalDetailColumns;
  };

  // funcion para limpiar las celdas editadas
  const clearEditedCells = () => {
    const updatedRowData = rowData.map((row) => {
      const updatedRow = { ...row };
      Object.keys(updatedRow).forEach((key) => {
        if (key.startsWith('edited_')) {
          delete updatedRow[key];
        }
      });
      return updatedRow;
    });

    setRowData(updatedRowData);
    gridApiRef.current.setRowData(updatedRowData);
  };

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      document.body.style.overflow = 'hidden';
      setStyleState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  // función para el ocultamiento de columnas custom
  const handleChangeCols = (event) => {
    if (gridApiRef.current) {
      let colsToHide = [];

      const allColumns = gridApiRef.current.getColumns();
      allColumns.forEach((column) => {
        column.setVisible(true);
      });

      if (event.target.value === '0') {
        console.log('entra en 0, todas visibles');
      }

      if (event.target.value === '1') {
        console.log('entra en 1 - Caracter + resumen');
        let colsToHide1 = [];
        let colsToHide2 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => col.colId.startsWith('day_'));

        gridApiRef.current.setColumnsVisible(colsToHide1, false);

        colsToHide2 = gridApiRef.current
          .getColumns()
          .filter((col) => col.colId.startsWith('total'));

        gridApiRef.current.setColumnsVisible(colsToHide2, false);
      }

      if (event.target.value === '2') {
        console.log('entra en 2 - Caracter + Semanas');

        let colsToHide1 = [];
        let colsToHide2 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => arrayColsResume.includes(col.colId));

        gridApiRef.current.setColumnsVisible(colsToHide1, false);

        colsToHide2 = gridApiRef.current
          .getColumns()
          .filter((col) => col.colId.startsWith('total'));

        gridApiRef.current.setColumnsVisible(colsToHide2, false);
      }

      if (event.target.value === '3') {
        console.log('entra en 3 - Caracter + Totales');

        let colsToHide1 = [];
        let colsToHide2 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => col.colId.startsWith('day_'));

        gridApiRef.current.setColumnsVisible(colsToHide1, false);

        colsToHide2 = gridApiRef.current
          .getColumns()
          .filter((col) => arrayColsResume.includes(col.colId));

        gridApiRef.current.setColumnsVisible(colsToHide2, false);
      }

      setCustomGroupColsState(event.target.value);

      customGroupColsRef.current = event.target.value;
    }
  };

  // confirma si debe tener master row, si tiene datos internos
  const isRowMaster = useCallback((dataItem) => {
    return dataItem ? dataItem.variantDetails.length > 0 : false;
  }, []);

  useEffect(() => {
    if (gridApiRef.current && customGroupColsState) {
      gridApiRef.current.openToolPanel('custom');
    }
  }, [customGroupColsState]);

  // genera el panel custom
  const customPanel = () => {
    return (
      <div className='p-2'>
        <FormControl>
          <RadioGroup value={customGroupColsState} onChange={handleChangeCols}>
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='0'
              label='Todas'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='1'
              label='Caracterización + Resumen'
            />
            <FormControlLabel
              control={<Radio />}
              name='resumen'
              value='2'
              label='Caracterización + Semanas'
            />
            <FormControlLabel
              control={<Radio />}
              name='resumen'
              value='3'
              label='Caracterización + Totales'
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  // genera el sidebar Custom
  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: 'custom',
        labelDefault: 'Personalizadas',
        labelKey: 'customStats',
        iconKey: 'columns',
        toolPanel: customPanel,
        toolPanelParams: {},
      },

      // {
      //     id: 'filters',
      //     labelDefault: 'Filters',
      //     labelKey: 'filters',
      //     iconKey: 'filter',
      //     toolPanel: 'agFiltersToolPanel',
      //     minWidth: 180,
      //     maxWidth: 400,
      //     width: 250
      // }
    ],
    position: 'right',
    defaultToolPanel: 'customPanel',
  };

  // componente de panel detalle para las filas
  const detailCellRendererParams = useMemo(
    () => ({
      detailGridOptions: {
        columnDefs: detailColumnsState,
        rowClass: 'inner-row-class',
        // turns OFF row hover, it's on by default
        suppressRowHoverHighlight: true,
        // turns ON column hover, it's off by default
        columnHoverHighlight: false,
        suppressHeaderHoverHighlight: true,
        defaultColDef: {
          flex: 1,
          rowClass: 'inner-row-class',
          suppressHeaderMenuButton: true,
          suppressHeaderFocus: true,
          suppressHeaderHoverHighlight: true,
          columnHoverHighlight: false,
          headerClass: 'custom-header',
          sortable: false,
        },
        // headerHeight: 38,
      },
      getDetailRowData: ({ successCallback, data: { variantDetails } }) => {
        return successCallback(variantDetails);
      },
    }),
    [detailColumnsState]
  );

  //----------------- GUARDAR DE TABLA
  // convierte las filas a celdas para guardar
  const convertRowDataToCells = (rowData, filterData) => {
    const cells = [];

    const dayMapping = {};
    filterData.days.forEach((week) => {
      week.daysNamesValues.forEach((dayName, index) => {
        const key = `day_${dayName.toLowerCase()}_${week.weekYear}`;
        dayMapping[key] = week.days[index];
      });
    });

    rowData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key.startsWith('day_')) {
          cells.push({
            // id: row.id,
            variety: row.variety,
            varietyValue: row.varietyValue,
            productLine: row.productLine,
            productLineValue: row.productLineValue,
            group: row.group,
            groupValue: row.groupValue,
            specie: row.specie,
            specieValue: row.specieValue,
            orchard: row.orchard,
            orchardValue: row.orchardValue,
            agroBusiness: row.agroBusiness,
            agroBusinessValue: row.agroBusinessValue,
            technicBoss: row.technicBoss,
            technicBossValue: row.technicBossValue,
            csg: row.csg,
            season: row?.season,
            // day: key,
            date: dayMapping[key] || null,
            kilos: row[key],
          });
        }
      });
    });

    return cells;
  };

  // guarda los datos
  const onSaveData = async () => {
    clearFocusSelection();

    setLoadingSubmitState(true);

    const newArray = convertRowDataToCells(rowData, dataDaysState);

    const dataObj = {
      userId: userId,
      client: userClient?.label || '',
      clientValue: userClient?.value || '',
      days: newArray,
    };

    // console.log(newArray, '<<<<<------newArray');

    try {
      const response = await axiosContext.authAxios.post(
        endPointNamePost,
        dataObj
      );

      const { status } = response;

      if (status === 200) {
        setSuccessSubmitState(true);
        alertRef.current = false;
        setCopyButtonState(false);

        clearEditedCells();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitState(false);
    }
  };

  //

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();

  const [dataDaysState, setDataDaysState] = useState([]);

  // const useOrchard = useWatch({
  //   control,
  //   name: 'orchard',
  // });

  const useAgroBusiness = useWatch({
    control,
    name: 'agroBusiness',
  });

  const useSpecie = useWatch({
    control,
    name: 'specie',
  });

  const useProductionLine = useWatch({
    control,
    name: 'productionLine',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const useTechnicBoss = useWatch({
    control,
    name: 'technicBoss',
  });

  const useGroup = useWatch({
    control,
    name: 'group',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    setValue('until', addDays(new Date(watchSince), 14));
    setMaxDateValidationState(addDays(new Date(watchSince), 30));
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const objQuery = {
      clientValue: userClient.value,
      userOrchards: userOrchard,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointFilters,
        objQuery
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        data.orchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setDataFilterState(data.orchard);

        const flattenEndpointData = flattenEndpointDataHandler(data.orchard);

        setFiltersFlattenDataState(flattenEndpointData);

        // cargar con valores únicos
        const agroBusinessArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.agroBusiness,
          };
        });

        const uniqueAgroBusiness = getUniqueValuesMaster(
          agroBusinessArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueAgroBusiness.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setAgroBusinessOptionsState(uniqueAgroBusiness);

        const specieArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.specie,
          };
        });

        const uniqueSpecie = getUniqueValuesMaster(
          specieArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueSpecie, '<<<<<------uniqueSpecie------')

        setSpecieOptionsState(uniqueSpecie);

        const varietyArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.variety,
          };
        });

        const uniqueVariety = getUniqueValuesMaster(
          varietyArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        // console.log(uniqueVariety, '<<<<<------uniqueVariety------')

        setVarietyOptionsState(uniqueVariety);

        const groupArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.group,
          };
        });

        const uniqueGroup = getUniqueValuesMaster(
          groupArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueGroup.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataGroupState(uniqueGroup);

        const technicBossArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.technicBoss,
          };
        });

        const uniqueTechnicBoss = getUniqueValuesMaster(
          technicBossArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueTechnicBoss.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataTechnicBossState(uniqueTechnicBoss);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    // getFilterVarietyAsync();

    getFilterAsync();
  }, []);

  // UseEffect para cambios de filtros
  useEffect(() => {
    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      const agroBusinessFilter = useAgroBusiness
        ? ele?.agroBusiness.value === useAgroBusiness.value
        : true;
      // const orchardFilter = useOrchard
      //   ? ele?.orchard.value === useOrchard.value
      //   : true;
      const specieFilter = useSpecie
        ? ele?.specie.value === useSpecie.value
        : true;
      const productLineFilter = useProductionLine
        ? ele?.productLine.value === useProductionLine.value
        : true;
      // const varietyFilter = useVariety
      //   ? ele?.variety.value === useVariety.value
      //   : true;
      const groupFilter = useGroup ? ele?.group.value === useGroup.value : true;
      const technicBossFilter = useTechnicBoss
        ? ele?.technicBoss.value === useTechnicBoss.value
        : true;

      return (
        agroBusinessFilter &&
        // orchardFilter &&
        specieFilter &&
        productLineFilter &&
        // varietyFilter &&
        groupFilter &&
        technicBossFilter
      );
    });

    // console.log(filterUpdatedData, '<<<<<------filterData------');
    const filterUpdatedDataVariety = filterUpdatedData.filter((ele) => {
      // buscarlo con some en el array de useOrchard
      const varietyFilter =
        useVariety && useVariety.length
          ? useVariety.some((variety) => variety.value === ele?.variety.value)
          : true;

      return varietyFilter;
    });

    // console.log(filterUpdatedDataVariety, '<<<<<------filterDataVariety------');

    const agroBusinessArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.agroBusiness,
      };
    });

    const uniqueAgroBusiness = getUniqueValuesMaster(
      agroBusinessArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setAgroBusinessOptionsState(uniqueAgroBusiness);

    if (!useAgroBusiness && uniqueAgroBusiness.length === 1) {
      setValue('agroBusiness', uniqueAgroBusiness[0]);
    }

    const orchardArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.orchard,
      };
    });

    const uniqueOrchards = getUniqueValuesMaster(
      orchardArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setFilterOrchardState(uniqueOrchards);

    // if (!useOrchard && uniqueOrchards.length === 1) {
    //   setValue('orchard', uniqueOrchards[0]);
    // }

    const specieArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.specie,
      };
    });

    const uniqueSpecie = getUniqueValuesMaster(
      specieArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setSpecieOptionsState(uniqueSpecie);

    if (!useSpecie && uniqueSpecie.length === 1) {
      // console.log(uniqueSpecie, '<<<<<------Entra para seterlo otra vez------');

      setValue('specie', uniqueSpecie[0]);
    }

    const productLineArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.productLine,
      };
    });

    const uniqueProductLine = getUniqueValuesMaster(
      productLineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setProductionLineDataState(uniqueProductLine);

    // if (!useProductionLine && uniqueProductLine.length === 1) {
    //   setValue('productionLine', uniqueProductLine[0]);
    // }

    const varietyArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.variety,
      };
    });

    const uniqueVariety = getUniqueValuesMaster(
      varietyArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    setVarietyOptionsState(uniqueVariety);

    const groupArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.group,
      };
    });

    const uniqueGroup = getUniqueValuesMaster(
      groupArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueGroup.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataGroupState(uniqueGroup);

    if (!useGroup && uniqueGroup.length === 1) {
      setValue('group', uniqueGroup[0]);
    }

    const technicBossArray = filterUpdatedDataVariety.map((ele) => {
      return {
        ...ele.technicBoss,
      };
    });

    const uniqueTechnicBoss = getUniqueValuesMaster(
      technicBossArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueTechnicBoss.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setFilterDataTechnicBossState(uniqueTechnicBoss);
  }, [
    useAgroBusiness,
    // useOrchard,
    useSpecie,
    useProductionLine,
    useVariety,
    useGroup,
    useTechnicBoss,
  ]);

  const onSubmit = async (values) => {
    if (alertRef.current) {
      handleClickOpenDialog();
      return;
    }

    setNoMessageKilosState(false);

    const dateBegin = values.since || '';
    const dateEnd = values.until || '';

    const dateBeginFormat = dateFormatIso(dateBegin);
    const dateEndFormat = dateFormatIso(dateEnd);

    if (dateBeginFormat > dateEndFormat) {
      // console.log(dateBeginFormat, dateEndFormat, '<<<<<------------');
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    setDataDaysState([]);
    setLoadingSubmitFilterState(true);

    let technicBossValueFilter = '';

    // Si es ID jefe técnico filtra con su username
    if (userIdProfile === '66b35738fce7da894e2b2572') {
      technicBossValueFilter = userFullname;
    } else {
      technicBossValueFilter = values.technicBoss?.value;
    }

    // AGREGADO de specie y productionLine
    const dataQuery = {
      clientValue: userClient.value,
      // orchardValue: values.orchard.value,
      // agroBusiness: values?.agroBusiness?.label,
      technicBossValue: technicBossValueFilter,
      agroBusinessValue: values?.agroBusiness?.value,
      varieties: values.variety && values.variety.length ? values.variety : [],
      // variety: values.variety?.label || '',
      specieValue: values?.specie?.value || '',
      productLineValue: values?.productionLine?.value || '',
      dateSince: dateFormatIso(dateBegin),
      dateUntil: dateFormatIso(dateEnd),
    };

    // console.log(values.orchard, orchard_filter, '---------evalua huertos');
    setInfoFilterTab({ orchard: values.orchard, ...values });

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        dataQuery
      );

      const { data, status } = response;

      // console.log(data, '<<<<<-------------------', paramsQuery);

      if (status === 200) {
        // setDataDaysState(MOCKDATA);

        // setVisibleColumnsState(processDataColumns(MOCKDATA.days));

        // setDetailColumnsState(processDataDetailColumn(MOCKDATA.days));

        // const rowDataFullTransform = processDataRowDetailFull(MOCKDATA);

        // REAL

        setDataDaysState(data);

        setVisibleColumnsState(processDataColumns(data.days));

        setDetailColumnsState(processDataDetailColumn(data.days));

        const rowDataFullTransform = processDataRowDetailFull(data);

        setRowData(rowDataFullTransform);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitFilterState(false);
    }
  };

  /*Funcionamiento de el boton de busqueda */
  const handleQuickFilterChange = (event) => {
    setQuickFilterText(event.target.value);
  };

  const closeHandler = () => {
    setSuccessSubmitState(false);
  };

  const onResetSearch = () => {
    setQuickFilterText('');
  };

  /*Funcionamiento del boton de exportar Csv*/
  const onBtnExport = () => {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv();
    }
  };

  // const resetFilters = () => {
  //   // console.log('reset filters');
  //   reset();
  //   setCopyButtonState(false);
  //   setSuccessSubmitState(false);
  //   setDataDaysState([]);
  // };

  useEffect(() => {
    if (userClient && userClient?.value === 'Garces_Fruit_2') {
      // console.log(userClient, '<<<<<------------');
      setGarces2State(true);
    }
  }, [userClient]);

  // funciones para hacer limpieza conjunta
  // en especie, linea de produccion y grupo
  // const handleClearSpecieValue = (value) => {
  //   console.log('clear value', value);
  //   if (!value) {
  //     setValue('specie', '');
  //     setValue('productionLine', '');
  //     setValue('variety', []);
  //   } else {
  //     setValue('specie', value);
  //   }
  // };

  // const handleClearProductLineValue = (value) => {
  //   console.log('clear value', value);
  //   if (!value) {
  //     setValue('specie', '');
  //     setValue('productionLine', '');
  //     setValue('variety', []);
  //   } else {
  //     setValue('productionLine', value);
  //   }
  // };

  // const handleClearGroupValue = (value) => {
  //   console.log('clear value', value);
  //   if (!value) {
  //     setValue('group', '');
  //     setValue('agroBusiness', '');
  //     setValue('orchard', '');
  //   } else {
  //     setValue('group', value);
  //   }
  // };

  const handleClickOpenDialog = () => {
    setOpenDialogState(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogState(false);
  };

  const handleCloseAndConfirmDialog = () => {
    setCopyButtonState(false);
    alertRef.current = false;
    handleSubmit(onSubmit)();

    setOpenDialogState(false);
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        {/* <BsRow class='no-gutters'>
          <BsCol class='col-12 mb-2 mb-md-3'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Bi Semanal - Cosecha
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-12 align-items-center'>
                    <MobileFilters>
                      <form
                        className='form-inline'
                        autoComplete='on'
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className='input-group mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.technicBoss}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='technicBoss'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataTechnicBossState}
                            placeholder='Jefe Técnico'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.specie}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='specie'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            onChange={handleClearSpecieValue}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={specieOptionsState}
                            placeholder='Especie'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.group}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='group'
                            isSearchable
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearGroupValue}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={filterDataGroupState}
                            placeholder='Grupo'
                          />
                        </div>

                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.agroBusiness}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='agroBusiness'
                            display='vertical'
                            variant='outlined'
                            isSearchable
                            isClearable={true}
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={agroBusinessOptionsState}
                            placeholder='Razón Social'
                          />
                        </div>
                        {/* <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.orchard}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='orchard'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            isMulti={false}
                            req={true}
                            defaultValue=''
                            options={filterOrchardState}
                            placeholder='Huerto'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.productionLine}
                            loading={loadingFiltersState}
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='productionLine'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            onChange={handleClearProductLineValue}
                            isSearchable
                            isMulti={false}
                            req={false}
                            defaultValue=''
                            options={productionLineDataState}
                            placeholder='l. Producción'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <SelectMultipleWidget2
                            errors={errors.variety}
                            loading={
                              loadingFiltersState || loadingVarietiesState
                            }
                            control={control}
                            menuPortalTarget={document.body}
                            labeltext=''
                            name='variety'
                            display='vertical'
                            variant='outlined'
                            isClearable={true}
                            isSearchable
                            isMulti={true}
                            req={false}
                            defaultValue=''
                            options={varietyOptionsState}
                            placeholder='Variedad'
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <TextFieldWidgetDateController2
                            name='since'
                            labeltext='Desde'
                            display='vertical'
                            variant='outlined'
                            control={control}
                            errors={errors.since}
                            format='dd-MM-yyyy'
                            defaultValue={subDays(new Date(), 1)}
                          />
                        </div>
                        <div className='input-group ml-md-2 mb-3'>
                          <TextFieldWidgetDateController2
                            name='until'
                            labeltext='Hasta'
                            format='dd-MM-yyyy'
                            display='vertical'
                            variant='outlined'
                            maxDate={maxDateValidationState}
                            control={control}
                            minDate={minDateState}
                            errors={errors.until}
                            // defaultValue={null}
                            defaultValue={new Date()}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            paddingTop: 5,
                          }}
                        >
                          <SaveAction
                            title='Filtrar'
                            color='secondary'
                            size='large'
                            className='roundedButton mx-md-2'
                            variant='contained'
                            messageType='add'
                            mutationLoading={
                              loadingSubmitFilterState || excelLoadingState
                            }
                            success={false}
                          />
                          <Button
                            variant='outlined'
                            size='large'
                            onClick={resetFilters}
                          >
                            Limpiar
                          </Button>
                        </div>
                      </form>
                      {errors.incorrectDates && (
                        <p
                          style={{
                            color: theme.palette.error.main,
                          }}
                        >
                          {errors.incorrectDates.message}
                        </p>
                      )}
                    </MobileFilters>
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow> */}
        <>
          <>
            <BsRow class='no-gutters'>
              <BsCol class='col-md-12'>
                <Card elevation={0}>
                  <CardContent sx={{ p: 0 }}>
                    <>
                      {/* PARA AVISAR DATOS NO GUARDADOS */}
                      <FormPrompt hasUnsavedChanges={copyButtonState} />
                      <Dialog
                        open={openDialogState}
                        keepMounted
                        onClose={handleCloseDialog}
                        aria-describedby='alert-dialog-slide-description'
                      >
                        <DialogContent>
                          <DialogContentText id='alert-dialog-slide-description'>
                            Tiene datos editados sin guardar.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Cancelar</Button>
                          <Button onClick={handleCloseAndConfirmDialog}>
                            Aceptar y Filtrar
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <>
                        <BsRow class='no-gutters'>
                          <BsCol class='col-md-12 pb-5'>
                            <div className='ag-theme-quartz' style={styleState}>
                              <div
                                className='d-flex justify-content-between pb-1 pt-2'
                                style={{ backgroundColor: 'white' }}
                              >
                                <Box
                                  sx={{
                                    justifyContent: 'flex-start',
                                    display: 'flex',
                                  }}
                                >
                                  <form
                                    className='form-inline'
                                    autoComplete='on'
                                    onSubmit={handleSubmit(onSubmit)}
                                  >
                                    <div className='input-group ml-md-2'>
                                      <TextFieldWidgetDateController2
                                        name='since'
                                        labeltext='Desde'
                                        placeholder='Desde'
                                        display='vertical'
                                        variant='outlined'
                                        defaultValue={subDays(new Date(), 1)}
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        control={control}
                                        errors={errors.since}
                                        // defaultValue={startColdDate}
                                      />
                                    </div>
                                    <div className='input-group ml-md-2'>
                                      <TextFieldWidgetDateController2
                                        name='until'
                                        labeltext='Hasta'
                                        placeholder='Hasta'
                                        display='vertical'
                                        variant='outlined'
                                        format='dd-MM-yyyy'
                                        style={{}}
                                        minheight={false}
                                        slotProps={{
                                          textField: {
                                            size: 'small',
                                          },
                                        }}
                                        sx={{
                                          width: '150px',
                                        }}
                                        // value={sinceSelectedState}
                                        // onChange={date => setSinceSelectedState(date)}
                                        minDate={minDateState}
                                        control={control}
                                        errors={errors.until}
                                        defaultValue={new Date()}
                                        // defaultValue={endColdDate}
                                      />
                                    </div>
                                    <SaveAction
                                      title='Filtrar'
                                      color='secondary'
                                      size='medium'
                                      className='roundedButton ml-md-2'
                                      variant='contained'
                                      disabled={loadingSubmitFilterState}
                                      messageType='add'
                                      mutationLoading={loadingSubmitFilterState}
                                      success={false}
                                    />
                                  </form>
                                </Box>
                                <Box
                                  sx={{
                                    // marginRight: 2,
                                    marginBottom: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                  }}
                                >
                                  <TextField
                                    variant='outlined'
                                    placeholder='Buscar...'
                                    label='Buscar'
                                    size='small'
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position='start'>
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                      endAdornment: quickFilterText ? (
                                        <IconButton
                                          color='primary'
                                          aria-label='clear'
                                          component='span'
                                          onClick={() => onResetSearch()}
                                        >
                                          <ClearIcon
                                            fontSize='small'
                                            style={{
                                              color: theme.palette.grey[400],
                                            }}
                                          />
                                        </IconButton>
                                      ) : null,
                                    }}
                                    value={quickFilterText}
                                    onChange={handleQuickFilterChange}
                                    sx={{
                                      width: 200,
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <ButtonAction
                                    labelButton='GUARDAR'
                                    color='secondary'
                                    size='large'
                                    actionOnClick={onSaveData}
                                    className='roundedButton'
                                    variant='contained'
                                    messageType='add'
                                    actionClose={closeHandler}
                                    fullWidth
                                    disabled={
                                      loadingSubmitFilterState ||
                                      !rowData ||
                                      !rowData.length
                                    }
                                    mutationLoading={loadingSubmitState}
                                    success={successSubmitState}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 2,
                                  }}
                                >
                                  <IconButton
                                    color='primary'
                                    aria-label='export'
                                    onClick={onBtnExport}
                                    disabled={loadingSubmitFilterState}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                  <IconButton
                                    color='primary'
                                    aria-label='export'
                                    onClick={() =>
                                      setFullScreen(!fullScreenState)
                                    }
                                    disabled={loadingSubmitFilterState}
                                  >
                                    {fullScreenState ? (
                                      <FullscreenExitIcon />
                                    ) : (
                                      <FullscreenIcon />
                                    )}
                                  </IconButton>
                                </Box>
                              </div>
                              {loadingSubmitFilterState && <LoadingWidget />}

                              {dataDaysState &&
                                dataDaysState?.days &&
                                dataDaysState?.days.length && (
                                  <AgGridReact
                                    rowData={rowData}
                                    columnDefs={visibleColumnsState}
                                    onCellValueChanged={onCellValueChanged}
                                    pagination
                                    paginationPageSize={50}
                                    suppressMovableColumns={true}
                                    suppressDragLeaveHidesColumns={true}
                                    paginationPageSizeSelector={
                                      paginationPageSizeSelector
                                    }
                                    localeText={localeText}
                                    defaultColDef={{
                                      flex: 1,
                                      minWidth: 150,
                                      filter: false,
                                      floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                                    }}
                                    masterDetail={true}
                                    detailCellRendererParams={
                                      detailCellRendererParams
                                    }
                                    enableRangeSelection={true}
                                    enableFillHandle={true}
                                    quickFilterText={quickFilterText}
                                    onGridReady={onGridReady}
                                    sideBar={sideBar}
                                    embedFullWidthRows={true}
                                    isRowMaster={isRowMaster}
                                    detailRowAutoHeight={true}
                                  />
                                )}
                            </div>
                          </BsCol>
                        </BsRow>
                      </>
                    </>
                  </CardContent>
                </Card>
              </BsCol>
            </BsRow>
          </>
        </>
      </BsContainer>
    </div>
  );
};

export default FormSchedule;
