// Importaciones de librerías
import _, { min } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { createSvgIcon } from '@mui/material/utils';
import { useHistory } from 'react-router-dom';

// Importaciones absolutas
// (No hay ninguna en tu ejemplo, pero las colocarías aquí)

// Importaciones relativas
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { Context } from '../../context/Context';
import { FiltersContext } from '../../context';
import { ButtonAction, CardPaper } from '../../components';
import {
  cherryIcon,
  // peachIcon,
  plumIcon,
  nectarineIcon,
} from '../../assets/icons/menuSpecie/icons_specie';
import logo from '../../assets/logo-white.png';
const useStyles = makeStyles((theme) => ({
  backgroundStyle: {
    backgroundImage:
      "url(\"data:image/svg+xml, %3Csvg width='100%25' height='100%25' id='svg' viewBox='0 0 1440 690' xmlns='http://www.w3.org/2000/svg' class='transition duration-300 ease-in-out delay-150'%3E%3Cpath d='M 0,700 L 0,262 C 242,295 484,328 724,328 C 964,328 1202,295 1440,262 L 1440,700 L 0,700 Z' stroke='none' stroke-width='0' fill='%23203543' fill-opacity='1' class='transition-all duration-300 ease-in-out delay-150 path-0' transform='rotate(-180 720 350)'/%3E%3C/svg%3E\")",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    // zIndex: 0,
  },
  theadLightSmall: {
    '& thead tr:nth-child(2) th': {
      backgroundColor: 'white',
    },
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
}));

const speciesIcons = {
  // Carozo: peachIcon,
  Cerezo: cherryIcon,
  Ciruelo: plumIcon,
  Nectarines: nectarineIcon,
};
const SpecieDashboard = () => {
  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  const { moduleUser, userOrchard, userClient, userFullname, userSpecies } =
    useContext(Context);

  const {
    state: {
      userOrchard_filter,
      orchard_filter,
      orchard_id_filter,
      userSpecies_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const handleButtonClick = (specie) => {
    addFilter({ specie_filter: specie });
    history.push({
      pathname: '/home',
    });
  };

  return (
    <div className={classes.backgroundStyle}>
      <BsContainer fluid class=''>
        <BsRow class=''>
          <BsCol class='col-md-12 mt-3'>
            <Box textAlign='center'>
              <img src={logo} alt='Analytics Logo' style={{ height: '40px' }} />
              <div style={{ height: 60 }} />
              <Typography variant='h3' sx={{ fontWeight: 600, color: 'white' }}>
                Bienvenido, {userFullname}
              </Typography>
              <div style={{ height: 15 }} />
              <Typography variant='h5' sx={{ fontWeight: 600, color: 'white' }}>
                Seleccione la especie
              </Typography>
              <BsRow class='gutter_10'>
                <BsCol class='col-md-12 mt-5'>
                  <Grid
                    container
                    columnSpacing={{ xs: 15 }}
                    columns={{ xs: 9, lg: 9 }}
                    spacing={5}
                    justifyContent='center'
                  >
                    {userSpecies_filter.map((specie) => {
                      const MapaIcon = createSvgIcon(
                        speciesIcons[specie.value],
                        'Mapa'
                      );

                      return (
                        <Grid
                          key={specie.id}
                          className='mb-3'
                          item
                          xs={4}
                          md={4}
                          lg={3}
                        >
                          <CardPaper
                            elevation={0}
                            topColor=''
                            variant='outlined'
                            borderTop={0}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: '100%',
                              minHeight: 110,
                              background:
                                theme.palette.speciesGradient[specie.value],
                              cursor: 'pointer',
                              border: 'none',
                            }}
                            onClick={() => handleButtonClick(specie.value)}
                          >
                            <MapaIcon sx={{ width: 48, height: 48 }} />
                            <Typography
                              variant='body2'
                              sx={{
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                color: '#FFFFFF',
                                mt: 1.4,
                              }}
                            >
                              {specie.label}
                            </Typography>
                          </CardPaper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </BsCol>
              </BsRow>
            </Box>
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default SpecieDashboard;
