export const tabItems = [
  {
    id: '6422f16d96ec727bd72c0df8',
    name: 'Planification',
    namePrint: 'Planificación',
    code: 'planification-process-001',
    order: 1,
    idModule: '651eab668dd2652ad27fc094',
  },
  {
    id: '6422f48710c22c4041f19b64',
    name: 'Certification',
    namePrint: 'Certificación',
    code: 'planification-process-002',
    order: 2,
    idModule: '651eab668dd2652ad27fc094',
  },
];
