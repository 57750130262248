import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useHistory, useRouteMatch } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import './carouselStyles.css';
import { Context } from '../../../../context/Context';
import { FiltersContext } from '../../../../context';
import { ButtonAction, MenuCarouselButton } from '../../../../components';
import { MODULE_CAROZO } from 'helpers/Mockups';

const useStyles = makeStyles((theme) => ({
  menuWrapper: (expand) => ({
    backgroundColor: theme.palette.background.default,
    height: expand ? 75 : 90,
    width: '100%',
    zIndex: 990,
    transition: 'height 0.3s ease-in-out',
  }),
  carouselContainer: {
    height: '100%',
    '&& .react-multi-carousel-track': {
      height: '100%',
    },
  },
  colInfoStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
      borderLeft: '1px solid',
      borderColor: theme.palette.grey[300],
    },
  },
  tableStyles: {
    marginTop: 5,
    '& td': {
      padding: '0.45rem',
      fontSize: 14,
    },
  },
  ccContainerStyle: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ccQuarterStyle: {
    marginTop: 20,
    paddingRight: 38,
  },
  quarterContainerStyle: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  activeButton: {
    backgroundColor: 'rgba(127,146,167, 0.3)',
  },
}));

const MenuV3Slider = ({ clickHandler, orchardHandler, moduleId, expand }) => {
  const classes = useStyles(expand);

  let history = useHistory();

  const { moduleUser } = useContext(Context);

  const {
    state: { specie_filter },
  } = useContext(FiltersContext);

  const handleButtonClick = (module) => {
    if (module.href) {
      history.push({
        pathname: module.href,
      });
    }
  };

  const handleActiveRoute = (module) => {
    const isCurrentPath = useRouteMatch(`/${module.href}`);

    // console.log(moduleId, '<---moduleId--', module);

    // si el module.id es igual a moduleId que defina como true sino false
    // ahora lo hace de esta forma, con el id que viene de Router
    return module.id === moduleId;

    // console.log(isCurrentPath, '<<<<<<-----------isCurrentPath', path);
    // return isCurrentPath?.isExact || false;
  };

  let modulesActive = moduleUser;

  if (specie_filter !== 'Cerezo' && moduleUser) {
    modulesActive = moduleUser.filter((module) =>
      MODULE_CAROZO.includes(module.id)
    );
  }

  return (
    <>
      <div className={classes.menuWrapper}>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=''
          containerClass={classes.carouselContainer}
          // customRightArrow={<CustomRightArrow />}
          dotListClass=''
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=''
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1460,
              },
              items: 10,
              partialVisibilityGutter: 20,
            },
            smallscreen: {
              breakpoint: {
                max: 1460,
                min: 1201,
              },
              items: 7,
              partialVisibilityGutter: 20,
            },
            tablet: {
              breakpoint: {
                max: 1200,
                min: 701,
              },
              items: 5,
              partialVisibilityGutter: 20,
            },
            mobile: {
              breakpoint: {
                max: 700,
                min: 0,
              },
              items: 3,
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=''
          slidesToSlide={1}
          swipeable
        >
          {modulesActive &&
            modulesActive.length &&
            modulesActive
              .filter((module) => module.assigned)
              .sort((a, b) => a.menuOrder - b.menuOrder)
              .map((module) => {
                return (
                  <Box
                    key={module.id}
                    onClick={() => handleButtonClick(module)}
                    sx={{ height: '100%', width: '100%' }}
                  >
                    <MenuCarouselButton
                      key={module.id}
                      selected={handleActiveRoute(module)}
                      name={module.namePrint}
                      icon={module.icon}
                      iconType={module.iconType || ''}
                    />
                  </Box>
                );
              })}
        </Carousel>
      </div>
    </>
  );
};

export default MenuV3Slider;
