import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { subDays } from 'date-fns';
import {
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  TextFieldWidgetDateController2,
  // TextFieldWidgetDateController,
} from '../../../components';
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  // Hidden,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useErrorHandler } from 'react-error-boundary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { makeStyles } from '@mui/styles';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import { Dashboard } from '../../../containers/Counting';
import _ from 'lodash';
import data from 'components/Dashboard/LatestOrders/data';
import { getOrchardsBySpecie } from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
}));

const CountingDashboard = (props) => {
  // const { userId, userHuerto, userName, moduleUser } = useContext(Context);

  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const errorHandler = useErrorHandler();

  const { orchard_filter, userOrchard_filter, specie_filter } = filterState;

  const axiosContext = useContext(AxiosContext);
  // axiosContext.authAxios //queries with token

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const theme = useTheme();

  // console.log(theme)

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusCounter';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const onSubmit = async (values) => {
    // setLoadingInfoState(true);

    // console.log(values, '-----------------------values', orchard_filter);

    const data = {
      orchards: values.orchard,
      specieValue: specie_filter,
    };

    setInfoFilterTab(data);

    if (values.orchard && values.orchard.length) {
      addFilter({
        orchard_filter: values.orchard[0].value,
        orchard_id_filter: values.orchard[0].id,
        cc_filter: '',
        quarter_filter: '',
      });
    } else {
      addFilter({
        orchard_filter: '',
        orchard_id_filter: '',
        cc_filter: '',
        quarter_filter: '',
      });
    }
  };

  useEffect(() => {
    handleSubmit(onSubmit)();
  }, []);

  console.log(infoFilterTab, specie_filter, '<<<<<---infoFilterTab');
  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='form-inline'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          {!loadingFiltersState ? (
            <>
              <div className='input-group'>
                <SelectMultipleWidget2
                  errors={errors.orchard}
                  loading={loadingFiltersState}
                  control={control}
                  menuPortalTarget={document.body}
                  labeltext=''
                  // req
                  name='orchard'
                  display='vertical'
                  variant='outlined'
                  isMulti={true}
                  isClearable={true}
                  isSearchable
                  onlyOptionValue={false}
                  defaultValue=''
                  options={getOrchardsBySpecie(
                    filterDataResponseState,
                    specie_filter
                  )}
                  placeholder='Huerto'
                />
              </div>
            </>
          ) : (
            <LoadingWidget />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-start',
              paddingTop: 5,
            }}
          >
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='large'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={loadingFiltersState}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </div>
        </form>
      </MobileFilters>
    );
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <Card elevation={0} variant='outlined'>
              <CardContent>
                <BsRow class=''>
                  <BsCol class='col-md-12'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Conteos Resumen
                    </Typography>
                    {renderFilters()}
                  </BsCol>
                </BsRow>
              </CardContent>
            </Card>
          </BsCol>
        </BsRow>
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <Dashboard filter={infoFilterTab} />
          </BsCol>
        </BsRow>
      </BsContainer>
    </div>
  );
};
export default CountingDashboard;
