import {
  Map,
  GoogleApiWrapper,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
  HeatMap,
  Circle,
} from 'google-maps-react';
import _, { max } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Backdrop,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Link,
  SvgIcon,
  Menu,
} from '@mui/material';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles, withStyles } from '@mui/styles';
import { CallReceived } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import {
  alterArrayAddColor,
  dashAdd,
  dashDelete,
  getCoords,
  getCoordsByGeom,
  getCoordsMulti,
  numberFormatDecimals,
} from '../../../helpers/functionHelpers';
import treeMarker from '../../../assets/planta1.png';

const useStyles = makeStyles((theme) => ({
  infoOrchardStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    padding: '8px 20px',
  },
  infoCuarterStyle: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '& table td': {
      fontSize: 14,
    },
  },
  irButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
  overlayButtonNoDrawer: {
    position: 'absolute',
    top: '100px',
    right: '0',
    zIndex: 979,
  },
  overlayButtonLayers: {
    position: 'absolute',
    top: '100px',
    right: '0',
    zIndex: 979,
  },
  menuItemStyle: {
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
    '& .MuiTypography-body2': {
      color: theme.palette.primary.main,
    },
  },
  layersButtonStyle: {
    textAlign: 'center',
    display: 'inline',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 25,
    backgroundColor: theme.palette.secondary.main,
  },
  layersButtonInactiveStyle: {
    textAlign: 'center',
    display: 'inline',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 25,
    backgroundColor: 'rgba(197,197,196,.9)',
  },
}));

const getMaxMinPercentage = (data) => {
  let maximo = Number.MIN_VALUE; // Inicializamos el máximo con el valor mínimo posible
  let minimo = Number.MAX_VALUE; // Inicializamos el mínimo con el valor máximo posible

  // Recorremos el array de objetos para encontrar el máximo y mínimo
  data.forEach((obj) => {
    const percentage = obj.percentage;
    if (percentage > maximo) {
      maximo = percentage; // Actualizamos el máximo si encontramos uno mayor
    }
    if (percentage < minimo) {
      minimo = percentage; // Actualizamos el mínimo si encontramos uno menor
    }
  });

  return { maximo, minimo };
};

const MapComponent = (props) => {
  const MapRef = useRef();

  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  let PolygonRef = [];
  let CircleRef = [];

  const errorHandler = useErrorHandler();

  const {
    // zonesHandler,
    plantsHandler,
    selectedGrid,
    colorsArray,
    loadingHandler,
    quarterFiltered,
    loadingState,
  } = props;

  const { userClient } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  // console.log(loadingState, 'loading en mapa');
  const [mapState, setMapState] = useState(/** google.maps.Map */ null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [layer2CirclesState, setLayer2CirclesState] = useState(false);

  const IconTree = {
    url: treeMarker, // URL del ícono personalizado
    scaledSize: new window.google.maps.Size(30, 30), // Tamaño del ícono
    origin: new window.google.maps.Point(0, 0), // Origen del ícono
    anchor: new window.google.maps.Point(15, 30), // Punto de anclaje del ícono
  };

  const {
    state: {
      // orchard_filter,
      // orchard_id_filter,
      userOrchard_filter,
      // cc_filter,
      // quarter_filter,
      // variety_filter,
      // rootstock_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showInfoWindowPolygon, setShowInfoWindowPolygon] = useState(false);
  const [showInfoWindowPolygonTooltip, setShowInfoWindowPolygonTooltip] =
    useState(false);
  const [activeQuarterDataState, setActiveQuarterDataState] = useState(null);

  const [activeCircleTooltipState, setActiveCircleTooltipState] = useState({});
  const [activeCircleTooltipDataState, setActiveCircleTooltipDataState] =
    useState(null);
  const [showInfoWindowCircle, setShowInfoWindowCircle] = useState(false);

  const [polygonFillColorState, setPolygonFillColorState] = useState([
    'rgba(255,255,255,0.1)',
    'rgba(255,255,255,0.9)',
  ]);

  const [quartersState, setQuartersState] = useState([]);

  const [mapInitialLocation, setMapInitialLocation] = useState({
    lat: -34.4142,
    lng: -70.7931,
  });

  const [initialZoom, setInitialZoom] = useState(12);

  // capas
  const [data_layer2State, setData_layer2State] = useState(null);

  // HEAT
  const [positionsHeatState, setPositionsHeatState] = useState(null);

  const [counterTypeState, setCounterTypeState] = useState(null);

  const [activateHeatMapState, setActivateHeatMapState] = useState(false);

  const [maxValuePercentageHeatmapState, setMaxValuePercentageHeatmapState] =
    useState(50);

  const [noPolygonUniteState, setNoPolygonUniteState] = useState(false);

  // plant1
  const [plant1State, setPlant1State] = useState(null);

  const style = {
    width: '100%',
    height: '100%',
  };

  // const setPolygonOptions = (options) => {
  // 	PolygonRef.current.polygon.setOptions(options);
  // };

  // timer query
  const [actualQuarter, setActualQuarter] = useState(null);
  const [lastQuarter, setLastQuarter] = useState(null);
  const [waitingTime, setWaitingTime] = useState(500);

  const abortControllerRef = useRef(null);

  const onMapClicked = (props) => {
    if (showInfoWindowCircle) {
      setShowInfoWindowCircle(false);
      setActiveCircleTooltipDataState(null);
    }

    if (showInfoWindow) {
      setShowInfoWindow(false);
    }
    if (showInfoWindowPolygon) {
      for (let i = 0; i < quartersState.length; i++) {
        PolygonRef[i].polygon.setOptions({
          fillColor: polygonFillColorState[0],
        });
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }
  };

  const onInfoWindowClose = (props) => {
    if (showInfoWindow) {
      setShowInfoWindow(false);
    }

    if (showInfoWindowPolygon) {
      if (PolygonRef[0]) {
        for (let i = 0; i < quartersState.length; i++) {
          PolygonRef[i].polygon.setOptions({
            fillColor: polygonFillColorState[0],
          });
        }
      }
      setShowInfoWindowPolygon(false);
      setActiveQuarterDataState(null);
    }

    if (showInfoWindowPolygonTooltip) {
      setShowInfoWindowPolygonTooltip(false);
    }
  };

  const _onZoomChanged = (e) => {
    // console.log(e, MapRef.current.map.zoom);
    // setInitialZoom(MapRef.current.map.zoom);

    const minZoom = 16;
    // console.log(MapRef.current.map.getZoom(), '-----el zoom');
    if (MapRef.current.map.getZoom() < minZoom)
      MapRef.current.map.setZoom(minZoom);
  };

  const _onCenterChanged = (mapProps, map) => {
    setMapInitialLocation({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  // SE ACTUYALIZA CON CAMBIOS DE QUARTER
  useEffect(() => {
    if (quarterFiltered) {
      closeAllWindows();
      // setData_layer2State(null);
      // setLayer2CirclesState(null);
      // setPositionsHeatState(null);
      setNoPolygonUniteState(false);

      // console.log(variety_filter, '--entra--variety_filter');
      setCounterTypeState(quarterFiltered.counterTypeMapFilter);

      const result = userOrchard_filter.find(
        (ele) => ele.value === quarterFiltered.orchardMapFilter
      );

      // PLANT1
      const plant1Orchard = result.plant1 || [];

      console.log(
        plant1Orchard,
        '---------plant1Orchard',
        quarterFiltered.idUnitProductiveFilter
      );

      // find de plant1Orchard con idUnitProductive
      const plant1Quarter = plant1Orchard.find(
        (ele) => ele.idUnitProductive === quarterFiltered.idUnitProductiveFilter
      );

      console.log(plant1Quarter, '----------------plant1Quarter');

      if (plant1Quarter?.geom) {
        setPlant1State(plant1Quarter);
      } else {
        setPlant1State(null);
      }

      const areaOrchard = result.polygon ? result.polygon : null;

      const quarterPolygonSelected = areaOrchard
        .filter(
          (ele) =>
            ele.idUnitProductive === quarterFiltered.idUnitProductiveFilter
        )
        .slice(0, 1);

      // console.log(
      //   quarterFiltered,
      //   quarterPolygonSelected,
      //   '--------------em el mapa'
      // );

      if (quarterPolygonSelected.length === 0) {
        setNoPolygonUniteState(true);
      }

      setQuartersState(quarterPolygonSelected || []);

      handleChangeQuarter(quarterPolygonSelected || '');

      // setChecked([]);

      const bounds = new props.google.maps.LatLngBounds();

      const arrayCoords =
        quarterPolygonSelected && quarterPolygonSelected.length
          ? getCoordsByGeom(quarterPolygonSelected[0]?.geom)
          : [];

      // console.log(_.flatten(arrayCoords), '-----arrayCoords');

      const flattenArray = _.flatten(arrayCoords);

      for (let i = 0; i < flattenArray.length; i++) {
        // console.log(flattenArray[i], '-----bounds----');
        bounds.extend(flattenArray[i]);
      }

      setInitialZoom(19);

      setMapInitialLocation(bounds.getCenter());
      // if (mapState) mapState.fitBounds(bounds);
    } else {
      setQuartersState([]);
    }
  }, [quarterFiltered, mapState]);

  const _mapLoaded = (mapProps, map) => {
    const { google } = mapProps;
    setMapState(map);
    map.setOptions({
      mapTypeId: 'hybrid',
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      fullscreenControl: true,
      disableDefaultUI: false,
    });

    google.maps.event.addListener(map, 'bounds_changed', onBoundsChanged);

    function onBoundsChanged() {
      if (
        map.getDiv().firstChild.clientHeight === window.innerHeight &&
        map.getDiv().firstChild.clientWidth === window.innerWidth
      ) {
        // console.log('FULL SCREEN');
        setIsFullScreen(true);
      } else {
        // console.log('NOT FULL SCREEN');
        setIsFullScreen(false);
      }
    }
  };

  const closeToolTip = () => {
    setShowInfoWindowPolygonTooltip(false);
  };

  // tooltip Circle
  const onCircleClick = (props, marker, e, circle) => {
    // console.log(props, marker, e, circle, '----circle click');
    setActiveCircleTooltipState(e.latLng);
    setActiveCircleTooltipDataState(circle);
    setShowInfoWindowCircle(true);
  };

  const onPlantInfoWindowClose = (props) => {
    // console.log('sale del circle');
    setActiveCircleTooltipDataState(null);
    setShowInfoWindowCircle(false);
  };

  // polígonos del cuartel
  const polys =
    quartersState &&
    quartersState.map((quarter, index) => {
      return (
        <Polygon
          key={`${quarter.centro_costo}_${index}`}
          // ref={setRef(`cuarter_${index}`)}
          ref={(ref) => (PolygonRef[index] = ref)}
          paths={getCoordsByGeom(quarter.geom)}
          // onClick={(props, marker, e) =>
          //   !checked.length ? onPolygonClick(props, marker, e, quarter) : {}
          // }
          id={`${quarter.orchard}_${quarter.cc}_${quarter.quarter}`}
          onMouseout={closeToolTip}
          strokeColor='#FFFFFF'
          strokeOpacity={1}
          strokeWeight={2}
          // fillColor={quarter.color}
          fillOpacity={1}
          options={{
            fillColor: polygonFillColorState[0],
          }}
        />
      );
    });

  // variable que renderia los círculos del mapa, plantas
  const layer2Circles =
    layer2CirclesState &&
    layer2CirclesState.map((circle, index) => {
      if (circle.geom.coordinates.length) {
        if (
          !circle.geom.coordinates[0].includes(null) &&
          circle.geom.coordinates[0]
        ) {
          // console.log(getCoords(circle.geom.coordinates)[0], '----los circles');
          return (
            <Circle
              key={`circ_${index}`}
              radius={1.5}
              title={circle.plantNumber}
              // onClick={(props, marker, e) =>
              //   onCircleClick(props, marker, e, circle)
              // }
              onMouseover={(props, marker, e) =>
                onCircleClick(props, marker, e, circle)
              }
              onMouseout={() => onPlantInfoWindowClose()}
              // ref={setRef(`cuarter_${index}`)}
              ref={(ref) => (CircleRef[index] = ref)}
              center={getCoords(circle.geom.coordinates)[0]}
              // center={circle}
              strokeColor='transparent'
              strokeOpacity={0}
              strokeWeight={0}
              // fillColor={quarter.color}
              fillOpacity={1}
              fillColor={'rgba(255,255,255,0.7)'}
              zIndex={100 + index}
            />
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

  const gradient = [
    'rgba(0, 0, 255, 0)', // Azul transparente
    'rgba(0, 0, 255, 0.5)', // Azul transparente
    'rgba(0, 0, 255, 1)', // Azul sólido
    'rgba(153, 204, 255,1)',
    'rgba(255, 255, 0, 1)', // Amarillo
    'rgba(255, 165, 0, 1)', // Naranja
    'rgba(255, 0, 0, 1)', // Rojo intenso
    'rgba(143, 0, 62,1)',
  ];

  const layerHeatmap =
    positionsHeatState && positionsHeatState.length && activateHeatMapState ? (
      <HeatMap
        positions={positionsHeatState}
        dissipating={true} // Si deseas que el color se disipe gradualmente
        gradient={gradient} // Define el gradiente de colores
        maxIntensity={maxValuePercentageHeatmapState}
        opacity={1} // Define la opacidad del mapa de calor
        radius={60} // Define el tamaño del radio del punto de calor
        // Aquí puedes agregar más propiedades según tus necesidades
      />
    ) : null;

  useEffect(() => {
    if (data_layer2State) {
      let totalPercentage = 0;
      const arrayPoints = data_layer2State.map((tree) => {
        const coords = getCoords(tree.geom.coordinates)[0];

        const percentage = tree.percentage;

        totalPercentage += percentage;

        const maxPerc = getMaxMinPercentage(data_layer2State).maximo;
        const minPerc = getMaxMinPercentage(data_layer2State).minimo;

        const minRange = minPerc * 1.1;
        const midRange = (minPerc + maxPerc) / 2;
        const maxRange = maxPerc * 0.95;

        const maxValueHeat = maxPerc + 10;
        setMaxValuePercentageHeatmapState(maxValueHeat);

        // console.log(
        //   minRange,
        //   midRange,
        //   maxRange,
        //   'ranges',
        //   maxPerc,
        //   minPerc,
        //   '----',
        //   percentage
        // );

        let weightPoint = 1;
        if (percentage < minRange) {
          weightPoint = percentage * 1.1;
        } else if (percentage > minRange && percentage < midRange) {
          weightPoint = percentage * 1.15;
        } else if (percentage > midRange && percentage < maxRange) {
          // console.log('entre 5 y 6.2');
          weightPoint = percentage * 2;
        } else if (percentage > maxRange) {
          weightPoint = percentage * 7;
        }

        return {
          ...coords,
          weight: weightPoint,
        };
      });

      // console.log(arrayPoints, '--------------data al mapa Position Heat');

      // console.log('total porcentaje', totalPercentage);

      // console.log(getMaxMinPercentage(data_layer2State), '-----max min');

      setPositionsHeatState(arrayPoints);

      setLayer2CirclesState(data_layer2State);
    }
  }, [data_layer2State]);

  const searchDataHandler = async (quarter) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setData_layer2State(null);
    setLayer2CirclesState(null);
    plantsHandler(null);
    loadingHandler(1);
    // llama función para ejecutar la consulta
    // y establecer los resultados en el estado
    // console.log(
    //   '3---------ejecuta search endpoint, solo ejecuta si pasaron los 500ms',
    //   quarter
    // );

    try {
      const params = {
        clientValue: userClient?.value,
        orchardValue: quarter.orchard,
        ccValue: quarter.cc,
        quarterValue: quarter.quarter,
        varietyValue: dashAdd(quarter.variety),
        rootStockValue: dashAdd(quarter.rootStock),
        counterType: quarter.counterType,
        specieValue: specie_filter,
      };

      // console.log(params, '--------params a sampling heatmap');

      const response = await axiosContext.authAxios.get('v1/hotmapcounter', {
        params: params,
        signal: abortControllerRef.current?.signal,
      });

      console.log(response, '--------------------------response', params);

      const { data, status } = response;

      if (status === 200) {
        const resultGrid = data?.plants || [];

        setPositionsHeatState(null);

        setData_layer2State(resultGrid);

        // pasa a la tabla data plants
        plantsHandler(data);

        loadingHandler(0);
      } else {
        loadingHandler(0);
      }
    } catch (error) {
      if (error.name === 'CanceledError') {
      } else {
        errorHandler(error);
      }
    } finally {
      loadingHandler(0);
    }
  };

  // se ejecuta en cada cambio de cuartel
  const handleChangeQuarter = (quarter) => {
    // console.log(
    //   quarter,
    //   '----en handle change',
    //   quarterFiltered.counterTypeMapFilter
    // );

    if (
      quarter[0]?.idUnitProductive !== lastQuarter?.idUnitProductive ||
      quarterFiltered.counterTypeMapFilter !== lastQuarter.counterType
    ) {
      setData_layer2State(null);
      setLayer2CirclesState(null);
      setPositionsHeatState(null);
    }

    const quarterIdentifier = {
      orchard: quarter[0]?.orchard,
      cc: quarter[0]?.cc,
      quarter: quarter[0]?.quarter,
      variety: quarter[0]?.variety,
      rootStock: quarter[0]?.rootStock,
      idUnitProductive: quarter[0]?.idUnitProductive,
      counterType: quarterFiltered.counterTypeMapFilter,
    };

    // console.log('1---cambio cuartel------', quarterIdentifier);
    setActualQuarter(quarterIdentifier);

    setWaitingTime(500);

    // Cancelar la búsqueda anterior si hay una
    if (waitingTime !== 500) {
      clearTimeout(waitingTime);
    }
  };

  // ufe para debounce,
  // si cuando se cumple el tiempo
  // cuartel actual distinto del último,
  // ejecuta la query
  useEffect(() => {
    const timer = setTimeout(() => {
      // compara cuartel y/o variedad para ejecutar query
      // console.log(actualQuarter, '---actualQuarter');
      if (actualQuarter) {
        // console.log('2---compara cuartel----', actualQuarter);
        if (
          // actualQuarter.cc !== lastQuarter?.cc ||
          // actualQuarter.quarter !== lastQuarter?.quarter ||
          // actualQuarter.variety !== lastQuarter?.variety ||
          // actualQuarter.counterType !== lastQuarter?.counterType
          actualQuarter.idUnitProductive !== lastQuarter?.idUnitProductive ||
          actualQuarter.counterType !== lastQuarter?.counterType
        ) {
          // console.log(
          //   '2.5---compara cuartel y es diferente----',
          //   actualQuarter
          // );

          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
          searchDataHandler(actualQuarter);
          // console.log('ejecuta search data');

          const quarterIdentifier = {
            cc: actualQuarter?.cc,
            quarter: actualQuarter?.quarter,
            variety: actualQuarter?.variety,
            rootStock: actualQuarter?.rootStock,
            idUnitProductive: actualQuarter?.idUnitProductive,
            counterType: actualQuarter?.counterType,
          };

          // console.log(quarterIdentifier, '---fija el last');

          setLastQuarter(quarterIdentifier);
        }
      }
      setWaitingTime(500);
    }, waitingTime);

    // Limpiar el temporizador anterior si hay uno
    return () => clearTimeout(timer);
  }, [actualQuarter, lastQuarter, waitingTime]);

  const closeAllWindows = () => {
    // console.log('cierra las ventanas');
    setShowInfoWindowCircle(false);
  };

  useEffect(() => {
    // Verificar si la biblioteca de visualización ya está cargada
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.visualization
    ) {
      // La biblioteca de visualización está cargada
      // console.log('La biblioteca de visualización ya está cargada.');
      setActivateHeatMapState(true);
    } else {
      // La biblioteca de visualización no está cargada
      // console.log('La biblioteca de visualización no está cargada.');
    }
  }, [mapState, props, window]);

  console.log(plant1State, 'plant1State');

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {loadingState ? (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 979,
            backgroundColor: 'rgba(255,255,255,0)',
          }}
        >
          <CircularProgress style={{ color: 'white' }} />
        </div>
      ) : null}
      <Map
        style={style}
        google={props.google}
        onClick={onMapClicked}
        onZoomChanged={_onZoomChanged}
        onDragend={_onCenterChanged}
        // onCenterChanged={_onCenterChanged}
        initialCenter={mapInitialLocation}
        center={mapInitialLocation}
        zoom={initialZoom}
        // minZoom={17} // Zoom mínimo permitido
        // maxZoom={22} // Zoom máximo permitido
        ref={MapRef}
        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      >
        {/* TOOLTIP */}
        {activeCircleTooltipDataState && (
          <InfoWindow
            position={activeCircleTooltipState}
            visible={showInfoWindowCircle}
            onClose={onPlantInfoWindowClose}
          >
            <div className={classes.infoCuarterStyle}>
              {/* <p>
                <b>Nº Fila:</b> {activeCircleTooltipDataState.rowNumber}
              </p>
              <p>
                <b>Nº Planta:</b> {activeCircleTooltipDataState.plantNumber}
              </p> */}
              <table className='table table-sm table-borderless'>
                <tr>
                  <td>Hilera / Nº Planta:</td>
                  <td style={{ fontWeight: 700 }}>
                    {activeCircleTooltipDataState?.rowNumber || '-'} |{' '}
                    {activeCircleTooltipDataState?.plantNumber || '-'}
                  </td>
                </tr>
                <tr>
                  <td>Tipo Conteo:</td>
                  <td style={{ fontWeight: 700 }}>
                    {dashDelete(activeCircleTooltipDataState?.counterType) ||
                      '-'}
                  </td>
                </tr>
                {activeCircleTooltipDataState?.counter ===
                'Conteo_Centro_Frutal' ? (
                  <>
                    <tr>
                      <td>Dardos:</td>
                      <td style={{ fontWeight: 700 }}>
                        {activeCircleTooltipDataState?.dart || '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Ramillas:</td>
                      <td style={{ fontWeight: 700 }}>
                        {activeCircleTooltipDataState?.twig || '-'}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td>Frutos:</td>
                    <td style={{ fontWeight: 700 }}>
                      {activeCircleTooltipDataState?.fruit || '-'}
                    </td>
                  </tr>
                )}
                {/* <tr>
                  <td>Incidencia:</td>
                  <td style={{ fontWeight: 700 }}>
                    {numberFormatDecimals(
                      activeCircleTooltipDataState?.percentage,
                      1
                    ) || '-'}{' '}
                    %
                  </td>
                </tr> */}
              </table>
            </div>
          </InfoWindow>
        )}
        {layerHeatmap}
        {layer2Circles}
        {polys}
        {/* {renderZones()} */}
        {plant1State &&
          plant1State.geom &&
          plant1State.geom.coordinates &&
          plant1State.geom.coordinates.length && (
            <Marker
              key={plant1State.idUnitProductive}
              name={plant1State.idUnitProductive}
              position={getCoords([plant1State.geom.coordinates])[0]}
              icon={IconTree}
            />
          )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  language: 'es',
  // apiKey: 'AIzaSyCgDjp2D44L-NQ5okVOSlCXmBwtkScGmLM',
  apiKey: 'AIzaSyBnXQDrdvbXPg0GjUdQzC_-v0ZMa3q5U9k',
  libraries: ['visualization'],
})(MapComponent);
